/* eslint-disable spellcheck/spell-checker */
import React, { useState } from 'react';
import {
  Button, Dropdown,
} from '@salesforce/design-system-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import mapStateToProps from '../../mapStateToProps';
import Constants from '../../constants/constants';
import StatusIcon from '../../components/shared/StatusIcon/StatusIcon';
import SwalUtil from '../../utils/swal/swalUtil';
import Util from '../../util';
import SortIcon from '../../components/shared/SortIcon/SortIcon';
import timeUtil from '../../utils/time/timeUtil';
import dragAndDropIcon from '../../icons_v2/drag_and_drop.svg';
import statusCheckIcon from '../../icons_v2/check.svg';
import Tip from '../shared_v2/Tip/Tip';

import './styles.scss';

const ActionIcon = ({
  rowIndex,
  handleOpenDeleteSelectionModal,
  handleOpenArchiveSelectionModal,
  handleOpenCopySelectionModal,
  isArchived,
  _id,
}) => {
  if (isArchived) {
    return (
      <Dropdown
        id={rowIndex}
        assistiveText={{ icon: 'More Options' }}
        buttonVariant="icon"
        iconCategory="utility"
        iconName="threedots_vertical"
        iconVariant="border-filled"
        style={{ maxHeight: '30px' }}
        length={5}
        onSelect={(item) => {
          if (item.label === 'Delete Selection') {
            handleOpenDeleteSelectionModal(_id);
          }
          if (item.label === 'Restore Selection') {
            handleOpenArchiveSelectionModal(_id);
          }

          if (item.label === 'Copy Selection') {
            handleOpenCopySelectionModal(_id);
          }
        }}
        options={[
          {
            label: 'Restore Selection',
            value: 'Restore Selection',
            className: 'des-lib-datatable-actions',
            leftIcon: {
              category: 'utility',
              name: 'archive',
            },
          },
          {
            label: 'Delete Selection',
            value: 'Delete Selection',
            className: 'slds-text-color_error des-lib-datatable-delete-action',
            leftIcon: {
              category: 'utility',
              name: 'delete',
              className: 'slds-icon-text-error',
            },
          },
        ]}
      />
    );
  }

  return (
    <Dropdown
      id={rowIndex}
      assistiveText={{ icon: 'More Options' }}
      buttonVariant="icon"
      iconCategory="utility"
      iconName="threedots_vertical"
      iconVariant="border-filled"
      style={{ maxHeight: '30px' }}
      length={5}
      onSelect={(item) => {
        if (item.label === 'Delete Selection') {
          handleOpenDeleteSelectionModal(_id);
        }
        if (item.label === 'Archive Selection') {
          handleOpenArchiveSelectionModal(_id);
        }

        if (item.label === 'Copy Selection') {
          handleOpenCopySelectionModal(_id);
        }
      }}
      options={[
        {
          label: 'Copy Selection',
          className: 'des-lib-datatable-actions',
          value: 'Copy Selection',
          leftIcon: {
            category: 'utility',
            name: 'copy',
          },
        },
        {
          label: 'Archive Selection',
          value: 'Archive Selection',
          className: 'des-lib-datatable-actions',
          leftIcon: {
            category: 'utility',
            name: 'archive',
          },
        },
        {
          label: 'Delete Selection',
          value: 'Delete Selection',
          className: 'slds-text-color_error des-lib-datatable-delete-action',
          leftIcon: {
            category: 'utility',
            name: 'delete',
            className: 'slds-icon-text-error',
          },
        },
      ]}
    />
  );
};

const DataTable = ({
  initialData,
  columns,
  overviewSection,
  sortDirection,
  handleSortSelections,
  sortedProperty,
  handleEditSelection,
  handleOpenDeleteSelectionModal,
  handleOpenCopySelectionModal,
  handleOpenArchiveSelectionModal,
  handleOpenSelectionRunLogsModal,
  startDraggingToFolder,
  handleSetOverviewState,
  openAllFolders,
  openSelections,
  featureSelectionFoldersEnabled,
  searchField,
  loadingSelectionsList,
  handleOpenNewSelection,
  userInfo,
  featureScheduleSelectionsIsEnabled,
  showCalendarViewForSchedules,
}) => {
  const data = initialData;
  const [reorderEnabled, setReorderEnabled] = useState(false);
  const userDateTimeFormat = timeUtil.getUserDateTimeFormat(userInfo);
  const userLocale = timeUtil.getUserLocale(userInfo);

  const reOrder = () => {
    setReorderEnabled(!reorderEnabled);
  };

  const renderSorting = (col) => {
    if (col === 'name') {
      return (
        <span
          id="nameSort"
          className="sorting slds-icon_container slds-icon-utility-announcement"
          onClick={() => handleSortSelections(
            'name',
            sortDirection === Constants.SORT_DIRECTION__ASCENDING ?
              Constants.SORT_DIRECTION__DESCENDING :
              Constants.SORT_DIRECTION__ASCENDING,
          )}
        >
          <SortIcon sortDirection={sortedProperty === col ? sortDirection : ''} />
        </span>
      );
    }

    if (col === 'records') {
      return (
        <span
          id="numberOfRecords"
          className="sorting slds-icon_container slds-icon-utility-announcement"
          onClick={() => handleSortSelections(
            'numberOfRecords',
            sortDirection === Constants.SORT_DIRECTION__ASCENDING ?
              Constants.SORT_DIRECTION__DESCENDING :
              Constants.SORT_DIRECTION__ASCENDING,
          )}
        >
          <SortIcon sortDirection={sortedProperty === 'numberOfRecords' ? sortDirection : ''} />
        </span>
      );
    }

    if (col === 'Last Run') {
      return (
        <span
          className="sorting slds-icon_container slds-icon-utility-announcement"
          onClick={() => handleSortSelections(
            overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS ?
              'waterfallSelectionCompletedDate' :
              'taskCompletedDate',
            sortDirection === Constants.SORT_DIRECTION__ASCENDING ?
              Constants.SORT_DIRECTION__DESCENDING :
              Constants.SORT_DIRECTION__ASCENDING,
          )}
        >
          <SortIcon sortDirection={
            sortedProperty === 'taskCompletedDate' ||
              sortedProperty === 'waterfallSelectionCompletedDate' ?
              sortDirection :
              ''
          }
          />
        </span>
      );
    }

    if (col === 'Created By') {
      return (
        <span
          id="createdBySort"
          className="sorting slds-icon_container slds-icon-utility-announcement"
          onClick={() => handleSortSelections(
            'createdBy',
            sortDirection === Constants.SORT_DIRECTION__ASCENDING ?
              Constants.SORT_DIRECTION__DESCENDING :
              Constants.SORT_DIRECTION__ASCENDING,
          )}
        >
          <SortIcon sortDirection={sortedProperty === 'createdBy' ? sortDirection : ''} />
        </span>
      );
    }

    if (col === 'Created At') {
      return (
        <span
          id="createdAtSort"
          className="sorting slds-icon_container slds-icon-utility-announcement"
          onClick={() => handleSortSelections(
            'createdAt',
            sortDirection === Constants.SORT_DIRECTION__ASCENDING ?
              Constants.SORT_DIRECTION__DESCENDING :
              Constants.SORT_DIRECTION__ASCENDING,
          )}
        >
          <SortIcon sortDirection={sortedProperty === 'createdAt' ? sortDirection : ''} />
        </span>
      );
    }

    if (col === 'Last Modified By') {
      return (
        <span
          className="sorting slds-icon_container slds-icon-utility-announcement"
          onClick={() => handleSortSelections(
            'updatedBy',
            sortDirection === Constants.SORT_DIRECTION__ASCENDING ?
              Constants.SORT_DIRECTION__DESCENDING :
              Constants.SORT_DIRECTION__ASCENDING,
          )}
        >
          <SortIcon sortDirection={sortedProperty === 'updatedBy' ? sortDirection : ''} />
        </span>
      );
    }

    if (col === 'Updated At') {
      return (
        <span
          className="sorting slds-icon_container slds-icon-utility-announcement"
          onClick={() => handleSortSelections(
            'updatedAt',
            sortDirection === Constants.SORT_DIRECTION__ASCENDING ?
              Constants.SORT_DIRECTION__DESCENDING :
              Constants.SORT_DIRECTION__ASCENDING,
          )}
        >
          <SortIcon sortDirection={sortedProperty === 'updatedAt' ? sortDirection : ''} />
        </span>
      );
    }
  };

  /**
   * Get the width of the column based on the column name to select the suitable width
   * @param {string} col - Column name
   * @returns {string} - Width of the column
   */
  const getColWidth = (col) => {
    if (['Status', 'Scheduled', 'Template', 'Action'].includes(col)) {
      return '100%';
    }

    if (col === 'Created By' || col === 'Last Modified By') {
      return '180px';
    }

    if (['Created At', 'Updated At', 'Last Run'].includes(col)) {
      return '120px';
    }

    return `${col.length * 15}px`;
  };

  const renderTableHeader = () => (
    <thead>
      <tr className="des-lib-datatable-tr">
        {columns?.map((col, index) => {
          if (overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS &&
            col === 'Template') {
            return null;
          }

          return (
            <th
              key={col}
              className={`des-lib-datatable-th ${col} ${index === 0 ? 'des-lib-datatable-sticky-column' : ''}`}
            >
              <div style={{ width: getColWidth(col) }}>
                <span>{col}</span>
                {renderSorting(col)}
              </div>
            </th>
          );
        })}
        <th className="des-lib-datatable-th">Action</th>
      </tr>
    </thead>
  );

  const rearrangeIcon = () => {
    return (
      <img
        className="des-lib-datatable-drag-and-drop-icon-shape"
        alt="Shape"
        onClick={reOrder}
        src={dragAndDropIcon}
      />
    );
  };

  const statusIcon = () => {
    return (
      <img className="des-lib-datatable-drag-and-drop-icon-shape" alt="Shape" src={statusCheckIcon} />
    );
  };

  /*
   * const errorIcon = () => {
   *   return (
   *       <Badge
   *       id="overview-status-error"
   *       color="error"
   *       content="Error"
   *       className="des-lib-datatable-error-container"
   *       />
   *   );
   * };
   */

  const trancate = (text, number) => {
    if (text?.length > number) {
      return text.slice(0, number) + '...';
    }

    return text;
  };

  /**
   * Render the right status icon
   * @param {number} queryRunStatus - Status of processing of run
   * @param {number} taskCompletedDate - taskCompletedDate
   * @param {number} taskError - taskError
   * @returns {object|undefined} The status icon
   */
  const mainStatusIcon = (queryRunStatus, taskCompletedDate, taskError) => {
    let output;

    // In case selection is going to be retried, add info about the retry
    const retryMessage = `<p class='bold_swal'>
    Selection will be retried at ${moment(taskCompletedDate).add(15, 'minutes').format(userDateTimeFormat)}
    </p>
    <br>`;

    const error = taskError;
    const isPermissionError = String(error)?.includes(Constants.ERROR_SFMC_PERMISSION_FAILED);

    const insufficientPermissionError = Constants.ERROR_INSUFFICIENT_PERMISSIONS_DETAILS;

    if (typeof queryRunStatus === 'number') {
      output = <StatusIcon
        status={queryRunStatus}
        showError={retry => SwalUtil.fire({
          title: isPermissionError ? 'Query Failed: Insufficient Permissions' : 'Query Failed',
          messageHTML: isPermissionError ? insufficientPermissionError : ((retry ? retryMessage : '') + error),
          type: Constants.SWAL__TYPE__ERROR,
        })}
      />;
    } else {
      // return Queued icon
      output = '';
    }

    return output;
  };

  /**
   * Format number of records based on the task status
   * @param {number} recordsNumber - record number
   * @param {number} runTaskStatus - task status
   * @param {number} taskError - taskError
   * @returns {string} - N/A or formatted number
   */
  const formatNumberOfRecords = (recordsNumber, runTaskStatus, taskError) => {
    // If the task is in any progress change the icons
    if (recordsNumber === null && runTaskStatus !== Constants.STATUS_ERROR ||
      runTaskStatus === Constants.STATUS_QUEUED_FOR_WATERFALL) {
      return <StatusIcon
        status={runTaskStatus}
        showError={() => SwalUtil.fire({
          title: 'Query Failed',
          message: taskError,
          type: Constants.SWAL__TYPE__ERROR,
        })} />;
    }

    // if there are numbers of records then return the number
    if (recordsNumber !== null && recordsNumber !== undefined && recordsNumber !== -1 &&
      typeof recordsNumber === 'number') {
      return Util.formatNumber(recordsNumber, 0, userLocale);
    }

    return 'N/A';
  };

  const getScheduleIconStyle = (scheduledRun) => {
    return {
      fill: `${scheduledRun?.enabled ? 'green' : 'gray'}`,
      cursor: `${scheduledRun?.enabled ? 'pointer' : 'default'}`,
    };
  };

  const renderTableRows = (row, col, index, index2) => {
    const runStatus = overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS ?
      row.waterfallSelectionStatus :
      row.runStatus;
    const taskCompletedDate = row.taskCompletedDate ||
      row.runDeduplicationTaskCompletedDate ||
      row.waterfallSelectionCompletedDate ||
      row.taskStartDate ||
      row.waterfallSelectionTaskStartDate;

    const taskError = overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS ?
      row.waterfallSelectionError :
      row.taskError || row.runDeduplicationTaskError;

    // check the first column
    if (index2 === 0) {
      return (
        <div
          className="des-lib-datatable-name"
          draggable={featureSelectionFoldersEnabled}
          onDragStart={() => {
            if (!openAllFolders && openSelections) {
              handleSetOverviewState({ blurDrop: true });
            } else {
              handleSetOverviewState({ folderBorderDragOver: true, draggedFolderId: '' });
              startDraggingToFolder(row._id);
            }
          }}
          onDragEnd={() => handleSetOverviewState({
            draggedSelectionId: '',
            folderBorderDragOver: false,
            blurDrop: false,
          })}
        >
          {rearrangeIcon(index)}
          <a
            href="#!"
            role="button"
            onClick={() => handleEditSelection(row._id, row.folderId)}
          >
            {trancate(row[col], 42)}
          </a>
        </div>
      );
    }
    // check status column
    if (col === 'Status') {
      return mainStatusIcon(runStatus, taskCompletedDate, taskError);
    }

    // check Last Run column
    if (col === 'Last Run') {
      if (
        taskCompletedDate === Constants.DUMMY_DATE__1990_01_01 ||
        taskCompletedDate === undefined ||
        taskCompletedDate === null
      ) {
        return '';
      }

      return (
        <span
          className="des-lib-datatable-last-run"
          onClick={() => handleOpenSelectionRunLogsModal(row._id)}
        >
          {timeUtil.formatDate(taskCompletedDate, userDateTimeFormat)}
        </span>
      );
    }

    // check Last Run column
    if (col === 'Records' && overviewSection === Constants.OVERVIEW__SECTION__SELECTIONS) {
      return formatNumberOfRecords(row.numberOfRecords, runStatus, taskError);
    }

    let nextRunDate = '';

    // if the featureScheduleSelectionsIsEnabled is enabled and there is scheduledRun object and it's enabled
    if (featureScheduleSelectionsIsEnabled && row?.scheduledRun && row?.scheduledRun.enabled) {
      // then calculate the next run date
      nextRunDate = timeUtil.calculateNextRunDate(row?.scheduledRun, userInfo);
    }

    // check scheduled selections
    if (col === 'Scheduled') {
      return (
        <span
          title={`${nextRunDate}`}
          onClick={row?.scheduledRun?.enabled ? () => showCalendarViewForSchedules(row?._id) : null}
        >
          <svg
            className="slds-icon  slds-icon-text-default"
            style={getScheduleIconStyle(row?.scheduledRun)}
            aria-hidden="true"
            id="schedule-icon"
          >
            <use xlinkHref="/assets/icons/standard-sprite/svg/symbols.svg#service_appointment_capacity_usage" />
          </svg>
        </span>
      );
    }

    // check created by
    if (col === 'Created By') {
      return (
        <div className="abbreviate-on-overflow" title={row.createdBy}>
         {row.createdBy}
        </div>
      );
    }

    // check created At
    if (col === 'Created At') {
      return (
        <span>
          {timeUtil.formatDate(row.createdAt, userDateTimeFormat)}
        </span>
      );
    }

    // check Last Modified By
    if (col === 'Last Modified By') {
      return (
        <div className="abbreviate-on-overflow" title={row.updatedBy}>
          {row.updatedBy}
        </div>
      );
    }

    if (col === 'Updated At') {
      return (
        <span>
          {row.updatedAt ? timeUtil.formatDate(row.updatedAt, userDateTimeFormat) : null}
        </span>
      );
    }

    // check template
    if (col === 'Template') {
      if (row.isTemplate) {
        return (
          <span>
            {statusIcon()}
          </span>
        );
      }
    }

    return row[col];
  };

  const renderTableBody = rowData => (
    <tbody>
      {rowData.map((row, index) => (
        <tr
          className="des-lib-datatable-tr"
          key={row._id}
        >
          {columns.map((col, index2) => {
            if (overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS &&
              col === 'Template') {
              return null;
            }

            return (
              <td
                key={col.id}
                className={`des-lib-datatable-td ${col} ${index2 === 0 ? 'des-lib-datatable-sticky-column' : ''}`}
              >
                {renderTableRows(row, col, index, index2)}
              </td>
            );
          })}
          <td className="des-lib-datatable-td">
            <ActionIcon
              rowIndex={index}
              isArchived={row.isArchived}
              handleOpenDeleteSelectionModal={handleOpenDeleteSelectionModal}
              handleOpenArchiveSelectionModal={handleOpenArchiveSelectionModal}
              handleOpenCopySelectionModal={handleOpenCopySelectionModal}
              _id={row._id}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );

  const renderCreateButton = () => {
    const returnDescription = () => {
      // Waterfall selections
      if (overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS) {
        return (
          <span>
          A Waterfall Selection is chain of Selections that are executed in order.
          Start by
          {' '}
          <Button
            type="button"
            onClick={handleOpenNewSelection}
            className="des-lib-datatable-create-selection"
          >
            creating a new one
          </Button>
          .
          </span>
        );
      }

      // Regular selections
      return (
        <span>
        A Selection is a concept used in DESelect Segment.
        You can think of it as a Segment, Query, or a very advanced type of filter.
        Start by
        {' '}
        <Button
          type="button"
          onClick={handleOpenNewSelection}
          className="des-lib-datatable-create-selection"
        >
          creating a new one
        </Button>
        .
        </span>
      );
    };

    return (
        <Tip
          title="Tip:"
          description={returnDescription()}
          mode="dark"
        />
    );
  };

  return (
    <div className={data?.length ?
      'des-lib-datatable-data-table-container' :
      'des-lib-datatable-data-table-container-stateles'}>
      {data?.length ?
        (
          <div className="des-lib-datatable-table-container">
            <table
              className="des-lib-datatable-table"
              id="selection-overview-table"
            >
              {renderTableHeader()}
              {renderTableBody(initialData)}
            </table>
          </div>
        ) :
        null}

      {!data.length && !loadingSelectionsList && searchField.length ?
        (
          <div>
            There is no selection with the string
            {' '}
            <b className="bold">{searchField.toString()}</b>
            {' '}
            in the name.
          </div>
        ) :
        null}

      {!loadingSelectionsList && !searchField.length ?
        (
          renderCreateButton()
        ) :
        null}
    </div>
  );
};

DataTable.propTypes = {
  /**
   * It opens the DeleteSelectionModal
   */
  handleOpenDeleteSelectionModal: PropTypes.func,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(DataTable);
