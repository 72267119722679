import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';


import './styles.scss';

import SelectDown from './icons/SelectDown';
import SelectUp from './icons/SelectUp';
import SelectRight from './icons/SelectRight';
import Database from './icons/Database';
import Field from './icons/Field';
import Util from '../../../util';
import Constants from '../../../constants/constants';
import Tooltip from '../../shared/Tooltip/Tooltip';

const PopMenuDropdown = ({
  placeHolder,
  options,
  selectedOption,
  onSelectedChange = () => { },
  type = 'simple',
  size = 'small',
  disabled = false,
}) => {
  const selectRef = useRef(null);
  const popupRef = useRef(null);
  const [isOpened, setIsOpened] = useState(false);
  const [expandedLists, setExpandedLists] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState(options);
  const [selected, setSelected] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });

  const handleOnClick = () => {
    if (!disabled) {
      setIsOpened(!isOpened);
    }
  };

  const isListExpanded = listIdentifier => expandedLists.includes(listIdentifier);

  const getListTitleClassName = (listIdentifier) => {
    const isExpanded = isListExpanded(listIdentifier);

    return classNames({ 'list-title': true, 'list-border-bottom': !isExpanded });
  };

  const getListItemsContainerClassName = (listIdentifier) => {
    const isExpanded = isListExpanded(listIdentifier);

    return classNames({ 'list-items-container': true, 'list-border-bottom': isExpanded });
  };

  const handleCollapseExpandList = (listIdentifier, doNotCollapse = false) => {
    const isExpanded = isListExpanded(listIdentifier);

    if (isExpanded) {
      if (doNotCollapse) {
        return;
      }
      setExpandedLists(expandedLists.filter(list => list !== listIdentifier));
    } else {
      setExpandedLists([...expandedLists, listIdentifier]);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;

    setSearchValue(value);
  };

  // Make sure search results are updated based on search value
  useEffect(() => {
    if (type === 'advanced') {
      const filteredOptions = options.reduce((previousValue, currentOption) => {
        const filteredChildren = currentOption.children.filter(
          item => item.label.toLowerCase().includes(searchValue.trim().toLowerCase()),
        );

        return [...previousValue, { ...currentOption, children: filteredChildren }];
      }, []);

      if (searchValue === '') {
        setExpandedLists([]);
      } else if (filteredOptions.length) {
        filteredOptions?.forEach((option, index) => {
          if (option.children.length) {
            handleCollapseExpandList(`${option.value}-${index}`, true);
          }
        });
      }

      setSearchResults(filteredOptions);
    } else {
      setSearchResults(options);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  // Make sure if selected item is provided, we choose by default
  useEffect(() => {
    if (type === 'advanced') {
      if (selectedOption?.value && selectedOption?.label &&
        selectedOption?.parent?.value && selectedOption?.parent?.label) {
        setSelected(selectedOption);
      }
    } else {
      if (selectedOption) {
        const option = options.find(item => item.value === selectedOption.value) || null;

        setSelected(option);
      }
    }
  }, [selectedOption, type, options]);


  const handleSelect = (child, parent) => {
    const selected = { ...child };

    if (type === 'advanced') {
      selected.parent = { ...parent };
    }

    setSelected(selected);
    onSelectedChange(selected);
    setIsOpened(false);
  };

  const recalculatePosition = () => {
    const selectElement = selectRef.current;
    const popupElement = popupRef.current;

    if (selectElement && popupElement) {
      const selectRect = selectElement.getBoundingClientRect();
      const parentRect = selectElement.offsetParent.getBoundingClientRect();

      // Calculate position relative to the offset parent
      const top = selectRect.bottom - parentRect.top;
      const left = selectRect.left - parentRect.left;

      // Check if the popup would overflow the right edge of the viewport
      const rightOverflow = (left + popupElement.offsetWidth) > window.innerWidth;

      setPopupPosition({ top, left: rightOverflow ? 'auto' : left, right: rightOverflow ? 0 : 'auto' });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (isOpened) {
        recalculatePosition();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpened]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current) {
        const popupRect = popupRef.current.getBoundingClientRect();
        const selectRect = selectRef.current.getBoundingClientRect();
        const isOutsidePopup =
          event.clientX < popupRect.left ||
          event.clientX > popupRect.right ||
          event.clientY < popupRect.top ||
          event.clientY > popupRect.bottom;
        const isOutsideSelect =
          event.clientX < selectRect.left ||
          event.clientX > selectRect.right ||
          event.clientY < selectRect.top ||
          event.clientY > selectRect.bottom;

        if (isOpened && isOutsidePopup && isOutsideSelect) setIsOpened(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpened]);

  const getSelectClass = (selectSize) => {
    return classNames({
      select: true,
      [`${selectSize}-select`]: true,
      disabled,
    });
  };

  const getSelectItemTextClass = (selectSize) => {
    switch (selectSize) {
      case 'small':
        return 'selected-item-text small-selected-item-text';
      case 'large':
        return 'selected-item-text large-selected-item-text';
      default:
        return 'selected-item-text medium-selected-item-text';
    }
  };

  // Make sure the popup position is recalculated when the select is opened
  useEffect(() => {
    // Initially calculate position
    recalculatePosition();

    if (isOpened) {
      // Recalculate position
      document.addEventListener('scroll', recalculatePosition, true);

      // Clean up the event listener
      return () => {
        document.removeEventListener('scroll', recalculatePosition, true);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened]);

  return (
    <div className="select-main-container">
      <div className={getSelectClass(size)} ref={selectRef} onClick={handleOnClick}>
        <div className={getSelectItemTextClass(size)}>
          {selected ?
            <span>
              <span className="selected-item-parent-label" title={selected.label}>
                {selected.label}
              </span>
              {' '}
              {type === 'advanced' && (
                <span className="selected-item-label" title={selected.parent.label}>
                  {selected.parent.label}
                </span>)}
            </span> :
            placeHolder}
        </div>
        {isOpened ?
          (
            <SelectUp className="select-arrow-icon" />
          ) :
          (
            <SelectDown className="select-arrow-icon" />
          )}
      </div>
      {disabled && (
        <Tooltip
          align={Constants.SLDS_TOOLTIP_POSITION__BOTTOM_LEFT}
          type={Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE}
        />
      )}
      {!disabled && (
        <div
          className="select-popup"
          ref={popupRef}
          style={{ display: isOpened ? 'block' : 'none', top: popupPosition.top, left: popupPosition.left }}
        >
          {type === 'advanced' && (
            <div className="search-input-container">
              <input
                aria-label="search"
                type="text"
                className="search-input"
                placeholder="Search"
                value={searchValue}
                onChange={handleSearch}
              />
            </div>)}
          <div className="all-lists-container">
            {searchResults.map((option, index) => (
              <div className="list-container" key={`${option.value}-${index}`}>
                {type === 'advanced' ?
                  (
                    <div
                      className={getListTitleClassName(`${option.value}-${index}`)}
                      onClick={() => handleCollapseExpandList(`${option.value}-${index}`)}
                    >
                      <div className="list-title-group" title={option.label}>
                        <Database className="list-title-left-icon" />
                        {Util.abbreviate(option.label, 25)}
                      </div>
                      {
                        isListExpanded(`${option.value}-${index}`) ?
                          <SelectDown className="list-title-right-icon" /> :
                          <SelectRight className="list-title-right-icon" />
                      }
                    </div>) :
                  (
                    <div
                      className="list-title list-border-bottom list-title-simple"
                      onClick={() => handleSelect(option)}
                    >
                      <div
                        className="list-title-group"
                        title={option.label}
                      >
                        <Field className="list-item-left-icon" />
                        {Util.abbreviate(option.label, 25)}
                      </div>
                    </div>)}
                {isListExpanded(`${option.value}-${index}`) && type === 'advanced' && (
                  <div className={getListItemsContainerClassName(`${option.value}-${index}`)}>
                    {
                      option.children.map((child, childIndex) => (
                        <div
                          className="list-item"
                          key={`${option.value}-${index}-${child.value}-${childIndex}`}
                          onClick={() => handleSelect(child, option)}
                        >
                          <Field className="list-item-left-icon" />
                          {child.label}
                        </div>
                      ))
                    }
                  </div>)}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};


PopMenuDropdown.propTypes = {
  /**
   * Placeholder text for the dropdown when no option is selected
   */
  placeHolder: PropTypes.string,

  /**
   * Array of options for the dropdown
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),

  /**
   * Currently selected option
   */
  selectedOption: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    parent: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  }),

  /**
   * Callback function when an option is selected
   */
  onSelectedChange: PropTypes.func,

  /**
   * Type of dropdown: 'simple' or 'advanced'
   */
  type: PropTypes.oneOf(['simple', 'advanced']),

  /**
   * Size of the dropdown: 'small', 'medium', or 'large'
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),

  /**
   * Whether the dropdown is disabled
   */
  disabled: PropTypes.bool,
};

PopMenuDropdown.defaultProps = {
  placeHolder: '',
  onSelectedChange: () => {},
  type: 'simple',
  size: 'small',
  disabled: false,
};

export default PopMenuDropdown;
