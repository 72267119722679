import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import axios from 'axios';
import { Provider, connect } from 'react-redux';
import classNames from 'classnames';

import mapStateToProps from '../../mapStateToProps';
import Users from '../../components/AdminPanel/Users/Users';
import Constants from '../../constants/constants';
import UsersAPI from '../../api/users';
import PicklistsAPI from '../../api/picklists';
import SettingsAPI from '../../api/settings';
import Navbar from '../../components/AdminPanel/Navbar/Navbar';
import Picklists from '../../components/AdminPanel/Picklists/Picklists';
import PicklistAE from '../../components/AdminPanel/PicklistAddOrEdit/PicklistsAE';
import Relations from '../../components/AdminPanel/Relations/Relations';
import ContactAttributes from '../../components/AdminPanel/ContactAttributes/ContactAttributes';
import RelationPanel from '../../components/AdminPanel/Relations/RelationPanel/RelationPanel';
import LoadingModal from '../../components/shared/LoadingModal/LoadingModal';
import Tooltip from '../../components/shared/Tooltip/Tooltip';
import Util from '../../util';
import Features from '../../features';
import RelationsAPI from '../../api/relations';
import ContactAttributesAPI from '../../api/contact-attributes';
import DataExtensionFolders from '../../components/AdminPanel/DataExtensionFolders/DataExtensionFolders';
import FoldersAPI from '../../api/folders';
import organisationsAPI from '../../api/organisations';
import DataSets from '../../components/AdminPanel/DataSets/DataSets';
import FilterSets from '../../components/AdminPanel/FilterSets/FilterSets';
import DataSetsPanel from '../../components/AdminPanel/DataSets/DataSetsPanel/DataSetsPanel';
import FilterSetsPanel from '../../components/AdminPanel/FilterSets/FilterSetsPanel/FilterSetsPanel';
import Settings from '../../components/AdminPanel/Settings/Settings';
import GlobalCustomValuesPanel from '../../components/AdminPanel/GlobalCustomValues/GlobalCustomValuesPanel';
import GlobalCustomValue from '../../components/AdminPanel/GlobalCustomValues/GlobalCustomValue';
import DataSetsAPI from '../../api/data-sets';
import FilterSetsAPI from '../../api/filter-sets';
import GlobalCustomValuesAPI from '../../api/global-custom-values';
import store from '../../redux/store/store';
import AuditLogs from '../../components/AdminPanel/AuditLogs/AuditLogs';
import FeatureTitle from '../../components/shared/Feature/FeatureTitle';
import FeatureAdvertContainer from '../../components/AdminPanel/FeatureAdvertContainer/FeatureAdvertContainer';
import GuidanceTip from '../../components/shared/GuidanceTip/GuidanceTip';
import Banner from '../../components/Selection/Banner/Banner';
import DeedeeExtensions from '../../components/AdminPanel/DeedeeAIExtensions/DeedeeAIExtensions';

class AdminPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePanel: Constants.ADMIN_PANEL__MENU__USERS,
      submenu: Constants.ADMIN_PANEL__SUBMENU__AVAILABLE_DES,
      usersList: [],
      editedOrDeletedCategory: false,
      showBanner: true,
      notificationEmails: [],
      dataRetentionSettings: {
        dataRetentionPeriodLength: '',
        dataRetentionPeriod: Constants.DATA_RETENTION_POLICY__PERIOD__DAYS,
        dataRetentionPolicy: false,
      },
      dataRetentionSettingACDE: {
        dataRetentionPeriodLength: '',
        dataRetentionPeriod: Constants.DATA_RETENTION_POLICY__PERIOD__DAYS,
        dataRetentionPolicy: false,
        applyTo: Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__INDIVIDUAL_RECORDS,
      },
      sendRelationshipSettings: {
        isEnabled: false,
        selectedDataExtension: null,
        selectedField: null,
      },
      licensesInfo: {},
      loading: true,
      picklists: [],
      picklistId: '',
      sortedProperty: 'updatedAt',
      sortDirection: Constants.SORT_DIRECTION__DESCENDING,
      relations: [],
      contactAttributes: [],
      relationId: '',
      selectedRelation: {},
      isRelationsLoading: true,
      isPicklistsLoading: true,
      isUsersLoading: true,
      loadingFoldersSettings: false,
      foldersSettings: {},
      isDataSetsLoading: false,
      isFilterSetsLoading: false,
      isContactAttributesLoading: true,
      isSettingsLoading: true,
      dataSetId: '',
      dataSets: [],
      filterSetId: '',
      filterSets: [],
      selectedFilterSet: null,
      isGettingValuesFromDEStarted: false,
      globalCustomValues: [],
      isGlobalCustomValuesLoading: true,
      selectedCustomValue: null,
      featureAdvertModal: {
        show: false,
        feature: '',
      },
    };
    this.prevStateForPicklist = {
      picklistId: '',
      picklistName: '',
      picklistCustomerKey: '',
      selectedDataExtension: {},
      selectedField: {},
      dataExtensionFields: [],
      options: [],
      activeValues: [],
      inactiveValues: [],
      value: '',
      label: '',
    };
    this.axiosCancelToken = axios.CancelToken.source();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.axiosCancelToken = axios.CancelToken.source();
  }

  /**
   * Open default menu and stop loading
   * @returns {void}
   */
  async componentDidMount() {
    await this.openPanel(Constants.ADMIN_PANEL__MENU__USERS);

    this.setState({ loading: false });
  }

  /**
   * Call handleOpenFilterSetPanel when category is deleted or edited
   * @param {Object} prevProps - prevProps
   * @param {Object} prevState - prevState
   * @returns {void}
   */
  async componentDidUpdate(prevProps, prevState) {
    const { editedOrDeletedCategory } = this.state;

    if (editedOrDeletedCategory && editedOrDeletedCategory !== prevState.editedOrDeletedCategory) {
      // Check if editedOrDeletedCategory changed since the last update
      this.handleOpenFilterSetPanel();
    }
  }

  /**
   * update  editedOrDeletedCategory when category is edited or deleted
   * @param {string} status - status
   * @returns {void}
   */
  handleCheckEditOrDelete = (status) => {
    this.setState({ editedOrDeletedCategory: status });
  };

  /**
   * It helps to set AdminPanel's state
   * @param {object} newState - a new state object to be set
   * @returns {void}
   */
  handleSetAdminPanelState = (newState) => {
    this.setState(newState);
  };

  /**
   * Updates user data
   * @param {object} user - an object with user data
   * @returns {void}
   */
  handleUpdateAdminPanelState = async (user) => {
    await UsersAPI.updateUser(user._id, user, this.axiosCancelToken.token);
    const licensesInfo = await organisationsAPI.getLicensesInfo(this.axiosCancelToken.token);
    // Update licenses info state

    this.handleSetAdminPanelState({ licensesInfo });
  };

  /**
   * Handle open filter set panel
   * @returns {void}
   */
  handleOpenFilterSetPanel = () => {
    const { isGettingValuesFromDEStarted } = this.state;

    if (!isGettingValuesFromDEStarted) {
      this.setState({ activePanel: Constants.ADMIN_PANEL__MENU__FILTER_SETS }, () => {
        this.openPanel(Constants.ADMIN_PANEL__MENU__FILTER_SETS);
      });
    }
  };

  /**
   * Handle open audit logs panel
   * @returns {void}
   */
  handleOpenAuditLogsPanel = () => {
    this.setState({ activePanel: Constants.ADMIN_PANEL__MENU__AUDIT_LOGS });
    this.openPanel(Constants.ADMIN_PANEL__MENU__AUDIT_LOGS);
  };

  /**
   * Handle open deedee ai data extensions panel
   * @returns {void}
   */
  handleOpenDeedeeAIExtensionsPanel = () => {
    this.setState({ activePanel: Constants.ADMIN_PANEL__MENU__DEEDEE_AI_SCOPE_DES });
    this.openPanel(Constants.ADMIN_PANEL__MENU__DEEDEE_AI_SCOPE_DES);
  };

  /**
   * Handle open picklist panel
   * @returns {void}
   */

  handleOpenPicklistPanel = async () => {
    const { featuresInfo } = this.props;
    const featurePicklist = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__PICKLISTS);

    const { isGettingValuesFromDEStarted } = this.state;
    // Don't do anything if feature is disabled

    if (isGettingValuesFromDEStarted) return;

    if (featurePicklist) {
      /*
       * first sets the state and as callback of setting the states
       * make the call or there will be no set state
       */
      await this.setState({
        ...this.prevStateForPicklist,
      }, async () => {
        await this.openPanel(Constants.ADMIN_PANEL__MENU__PICKLISTS);
      });
    } else {
      this.showEssentialsUpgradeModal(Constants.FEATURE__PICKLISTS);
    }
  };

  /**
   * Handle open global custom values panel
   * @returns {void}
   */
  handleOpenGlobalCustomValuesPanel = async () => {
    const { featuresInfo } = this.props;
    const featureGlobalCustomValues = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__GLOBAL_CUSTOM_VALUES);

    if (featureGlobalCustomValues) {
      await this.setState({ activePanel: Constants.ADMIN_PANEL__MENU__GLOBAL_CUSTOM_VALUES__PANEL });
      await this.openPanel(Constants.ADMIN_PANEL__MENU__GLOBAL_CUSTOM_VALUES__PANEL);
    } else {
      this.showEssentialsUpgradeModal(Constants.FEATURE__GLOBAL_CUSTOM_VALUES);
    }
  };

  /**
   * Handler for when the user edit a picklist
   * @param {object} e - Event. Use e.target to get the value
   * @param {string} picklistId - Value of the picklist for edit mode
   * @param {string} picklistCustomerKey - Customer key
   * @returns {void} Will setState for picklistCustomerKey, picklistId and activePanel
   */
  handleEditPicklist = async (e, picklistId, picklistCustomerKey) => {
    e.preventDefault();

    this.setState({
      picklistCustomerKey,
      picklistId,
      activePanel: Constants.ADMIN_PANEL__MENU__EDITED_PICKLIST,
    });
  };

  /**
   * Handle click on relation option
   * @param {Object} option - option properties
   * @returns {void}
   */
  handleClickRelationOption = (option) => {
    // Don't allow click if option is disabled
    if (option.isDisabled || !option.featureAvailable) {
      this.showEssentialsUpgradeModal(option.feature);

      return;
    }
    const { isGettingValuesFromDEStarted } = this.state;

    if (!isGettingValuesFromDEStarted) {
      this.openPanel(option.value);
      this.setState({
        submenu: option.value,
      });
    }
  };

  /**
   * Handler for when the user edit a relation
   * @param {object} e - Event. Use e.target to get the value
   * @param {string} relationId - Value of the relation for edit mode
   * @returns {void} Will setState for relationId and activePanel
   */
  handleEditRelation = async (e, relationId) => {
    e.preventDefault();

    const { relations } = this.state;

    // find the relation based on relation id
    const relation = relations.find(rel => rel._id === relationId);

    // set in state to pass in edit relation panel
    this.setState({
      selectedRelation: relation,
      relationId,
      activePanel: Constants.ADMIN_PANEL__MENU__EDIT_RELATION,
    });
  };

  /**
   * Fetch/update contact attributes from SFMC CONTACT BUILDER
   * @returns {void}
   */
  handleFetchContactAttributes = async () => {
    // Set isContactAttributesLoading true to show loading while relations are fetched
    this.setState({ isContactAttributesLoading: true });

    const contactAttributes = await ContactAttributesAPI.fetchAttributeSets(this.axiosCancelToken.token);

    this.setState({
      contactAttributes,
      isContactAttributesLoading: false,
    });
  };

  /**
   * Handler for when the user edit a relation
   * @param {object} e - Event. Use e.target to get the value
   * @param {string} relationId - Value of the relation for edit mode
   * @returns {void} Will setState for relationId and activePanel
   */
  handleOpenContactRelation = async (e, relationId) => {
    e.preventDefault();

    const { contactAttributes } = this.state;

    // find the relation based on relation id
    const relation = contactAttributes.find(rel => rel._id === relationId);

    // set in state to pass in edit relation panel
    this.setState({
      selectedRelation: relation,
      relationId,
      activePanel: Constants.ADMIN_PANEL__MENU__EDIT_RELATION,
    });
  };

  /**
   * Handler for when the user edit a data set
   * @param {object} e - Event. Use e.target to get the value
   * @param {string} dataSetId - Id of the data set for edit mode
   * @returns {void} Will setState for dataSetId and activePanel
   */
  handleEditDataSet = async (e, dataSetId) => {
    e.preventDefault();

    const { dataSets } = this.state;

    // find the data set based on data set id
    const dataSet = dataSets.find(set => set._id === dataSetId);

    // set in state to pass in edit data set panel
    this.setState({
      selectedDataSet: dataSet,
      dataSetId,
      activePanel: Constants.ADMIN_PANEL__MENU_EDIT_DATA_SET,
    });
  };

  /**
   * Handler for when the user edit a filter set
   * @param {object} e - Event. Use e.target to get the value
   * @param {string} filterSetId - Id of the filter set for edit mode
   * @returns {void}
   */
  handleEditFilterSet = async (e, filterSetId) => {
    e.preventDefault();

    const { filterSets } = this.state;

    // find the filter set based on filter set id
    const filterSet = filterSets.find(f => f._id === filterSetId);

    // set in state to pass in edit filter set panel
    this.setState({
      selectedFilterSet: filterSet,
      filterSetId,
      activePanel: Constants.ADMIN_PANEL__MENU_EDIT_FILTER_SET,
    });
  };

  /**
   * Load data for panel
   * @param {string} activePanel - panel to load data for
   * @returns {void}
   */
  // eslint-disable-next-line react/sort-comp
  loadData = async (activePanel) => {
    switch (activePanel) {
      case Constants.ADMIN_PANEL__MENU__USERS:
        try {
          this.setState({
            activePanel, submenu: null,
          });

          const usersList = await UsersAPI.getUsers(this.axiosCancelToken.token);

          const licensesInfo = await organisationsAPI.getLicensesInfo(this.axiosCancelToken.token);

          this.setState({ usersList, licensesInfo, isUsersLoading: false });
        } catch (error) {
          this.handleSetAdminPanelState({ error, isUsersLoading: false });
        }
        break;
      case Constants.ADMIN_PANEL__MENU__PICKLISTS:
        try {
          this.setState({
            activePanel,
            submenu: null,
            picklistId: null,
            isPicklistsLoading: true,
          });

          // Fetch picklists
          const picklists = await PicklistsAPI.getPicklists(this.axiosCancelToken.token);

          // Update state
          this.setState({
            picklists, isPicklistsLoading: false,
          });
        } catch (error) {
          this.handleSetAdminPanelState({ error, isPicklistsLoading: false });
        }
        break;
      case Constants.ADMIN_PANEL__SUBMENU__PREDEFINED_RELATIONS:
        try {
          this.setState({
            activePanel, submenu: Constants.ADMIN_PANEL__SUBMENU__PREDEFINED_RELATIONS,
          });

          // Set isRelationsLoading true to show loading while relations are fetched
          this.setState({ isRelationsLoading: true });

          const allRelations = await RelationsAPI.getRelations(this.axiosCancelToken.token);

          const relations = allRelations.filter(rel => !rel?.isContactBuilderAttribute);

          // Also fetch data sets
          const dataSets = await DataSetsAPI.getDataSets(this.axiosCancelToken.token);

          // Set fetched relations and loading to hide
          this.setState({
            relations, relationId: null, isRelationsLoading: false, dataSets,
          });
        } catch (error) {
          this.handleSetAdminPanelState({ error, isRelationsLoading: false });
        }
        break;
      case Constants.ADMIN_PANEL__SUBMENU__CONTACT_ATTRIBUTES:
        try {
          this.setState({
            activePanel, submenu: Constants.ADMIN_PANEL__SUBMENU__CONTACT_ATTRIBUTES,
          });

          // Set isRelationsLoading true to show loading while relations are fetched
          this.setState({ isContactAttributesLoading: true });

          const contactAttributes = await ContactAttributesAPI.getContactRelations(this.axiosCancelToken.token);

          // Also fetch data sets
          const dataSets = await DataSetsAPI.getDataSets(this.axiosCancelToken.token);

          // Set fetched relations and loading to hide
          this.setState({
            contactAttributes, isContactAttributesLoading: false, dataSets,
          });
        } catch (error) {
          this.handleSetAdminPanelState({ error, isContactAttributesLoading: false });
        }
        break;
      case Constants.ADMIN_PANEL__MENU__DE_FOLDERS:
        // Set loading state to true
        this.setState({ loadingFoldersSettings: true });

        try {
          // Get folders settings
          const foldersSettings = await FoldersAPI.getFoldersSettings();

          // Set state
          this.setState({ foldersSettings, loadingFoldersSettings: false });
        } catch (error) {
          // Set state
          this.handleSetAdminPanelState({ error, loadingFoldersSettings: false });
        }
        break;
      case Constants.ADMIN_PANEL__MENU__DATA_SETS:
        try {
          // Set isDataSetsLoading true to show loading while data sets are being fetched
          this.setState({ isDataSetsLoading: true, activePanel });

          const dataSets = await DataSetsAPI.getDataSets(this.axiosCancelToken.token);

          // Set fetched data sets and loading to hide
          this.setState({
            dataSets, dataSetId: null, isDataSetsLoading: false, submenu: null,
          });
        } catch (error) {
          this.handleSetAdminPanelState({ error, isDataSetsLoading: false });
        }
        break;
      case Constants.ADMIN_PANEL__MENU__FILTER_SETS:
        try {
          // Set isDataSetsLoading true to show loading while data sets are being fetched
          this.setState({ isFilterSetsLoading: true, activePanel });

          const filterSetsData = await FilterSetsAPI.getFilterSets(this.axiosCancelToken.token);

          const newFilterSetsArray = [];

          filterSetsData.forEach((filterSet) => {
            if (filterSet?.category?.name) {
              filterSet.categoryName = filterSet?.category?.name;
              filterSet.categoryId = filterSet?.category?._id;
            }
            if (!filterSet.category) {
              filterSet.categoryName = 'Uncategorized';
            }
            newFilterSetsArray.push(filterSet);
          });

          const filterSets = newFilterSetsArray;

          // Also fetch data sets and picklists
          const dataSets = await DataSetsAPI.getDataSets(this.axiosCancelToken.token);
          const picklists = await PicklistsAPI.getPicklists(this.axiosCancelToken.token);

          // Set fetched data sets and loading to hide
          this.setState({
            filterSets, dataSets, picklists, filterSetId: null, isFilterSetsLoading: false, submenu: null,
          });
        } catch (error) {
          this.handleSetAdminPanelState({ error, isFilterSetsLoading: false });
        }
        break;
      case Constants.ADMIN_PANEL__MENU__AUDIT_LOGS:
        this.setState({
          activePanel, submenu: null,
        });
        break;
      case Constants.ADMIN_PANEL__MENU__SETTINGS:
        this.setState({
          activePanel, submenu: null,
        });

        try {
          const notificationEmails = await SettingsAPI.getNotificationEmails(this.axiosCancelToken.token);
          const dataRetentionSettings = await SettingsAPI.getDataRetentionSettings(this.axiosCancelToken.token);
          const dataRetentionSettingACDE = await SettingsAPI.getACDEDataRetentionSettings(this.axiosCancelToken.token);
          const sendRelationshipSettings = await SettingsAPI.getSendRelationshipSettings(this.axiosCancelToken.token);

          this.setState({
            notificationEmails,
            dataRetentionSettings,
            dataRetentionSettingACDE,
            sendRelationshipSettings,
            isSettingsLoading: false,
          });
        } catch (error) {
          this.handleSetAdminPanelState({ error, isSettingsLoading: false });
        }
        break;

      case Constants.ADMIN_PANEL__MENU__GLOBAL_CUSTOM_VALUES__PANEL:
        this.setState({
          activePanel, submenu: null,
        });

        try {
          // Fetch Shared Custom Values for BU
          const globalCustomValues = await GlobalCustomValuesAPI.getGlobalCustomValues(this.axiosCancelToken.token);

          this.setState({
            globalCustomValues,
            isGlobalCustomValuesLoading: false,
          });
        } catch (error) {
          this.handleSetAdminPanelState({ error, isGlobalCustomValuesLoading: false });
        }

        break;

      default:
        break;
    }
  };

  /**
   * Loads one of the menu items in AdminPanel
   * @param {string} activePanel - Panel to switch to
   * @returns {void} Will setState activePanel
   */
  openPanel = async (activePanel) => {
    // Load relevant data and load component
    await this.loadData(activePanel);
  };

  /**
   * Navigates to active panel menu
   * @param {string} activePanel - Panel to switch to
   * @returns {void} Will setState activePanel
   */
  navigateToMenu = (activePanel) => {
    this.setState({
      activePanel,
    });
  };

  /**
   * Returns the correct JSX depending on which menu option the user is clicking
   * @param {string} selectedOption - value containing the activePanel state value
   * @returns {object} The HTML for the selected options
   */
  // eslint-disable-next-line react/sort-comp
  handleRenderOptionSelected(selectedOption) {
    const {
      usersList,
      notificationEmails,
      dataRetentionSettings,
      licensesInfo,
      picklists,
      picklistId,
      activePanel,
      picklistCustomerKey,
      sortDirection,
      sortedProperty,
      relations,
      relationId,
      selectedRelation,
      isRelationsLoading,
      submenu,
      foldersSettings,
      loadingFoldersSettings,
      isDataSetsLoading,
      isFilterSetsLoading,
      dataSetId,
      dataSets,
      filterSets,
      filterSetId,
      selectedDataSet,
      selectedFilterSet,
      isGettingValuesFromDEStarted,
      dataRetentionSettingACDE,
      sendRelationshipSettings,
      contactAttributes,
      isContactAttributesLoading,
      isPicklistsLoading,
      isUsersLoading,
      isSettingsLoading,
      globalCustomValues,
      isGlobalCustomValuesLoading,
      selectedCustomValue,
    } = this.state;

    const { handleSetAppState, handleNavigator } = this.props;

    // Set copy of picklist to use for sortProperty function
    let sortedPicklists = [...picklists];

    // sortedPicklists based on sortedProperty and sortedDirection
    sortedPicklists = Util.sortProperty(
      sortedPicklists,
      sortedProperty,
      sortDirection,
    );

    // Set copy of relation to use for sortProperty function
    let sortedRelations = [...relations];

    // sortedRelations based on sortedProperty and sortedDirection
    sortedRelations = Util.sortProperty(
      sortedRelations,
      sortedProperty,
      sortDirection,
    );

    // Set copy of contact Attributes to use for sortProperty function
    let sortedContactAttributes = [...contactAttributes];

    // sortedContactAttributes based on sortedProperty and sortedDirection
    sortedContactAttributes = Util.sortProperty(
      sortedContactAttributes,
      sortedProperty,
      sortDirection,
    );

    // Set copy of data sets to use for sortProperty function
    let sortedDataSets = [...dataSets];

    // sortedDataSets based on sortedProperty and sortedDirection
    sortedDataSets = Util.sortProperty(
      sortedDataSets,
      sortedProperty,
      sortDirection,
    );

    // sortedFilterSets based on sortedProperty and sortedDirection
    const sortedFilterSets = Util.sortProperty(
      [...filterSets],
      sortedProperty,
      sortDirection,
    );

    const sortedGlobalCustomValues = Util.sortProperty(
      [...globalCustomValues],
      sortedProperty,
      sortDirection,
    );

    let relationDataSet;

    // If we are entering in a relation
    if (selectedRelation._id) {
      // get a data set where that relation is used
      dataSets.forEach((dataSet) => {
        dataSet.relations.forEach((rel) => {
          if (((rel.fromCollectionObjectID === selectedRelation.fromDEObjectId) ||
            (rel.fromCollectionObjectID === selectedRelation.toDEObjectId)) &&
            ((rel.toCollectionObjectID === selectedRelation.toDEObjectId) ||
              (rel.toCollectionObjectID === selectedRelation.fromDEObjectId))) {
            relationDataSet = rel;
            relationDataSet.name = dataSet.name;
          }
        });
      });
    }

    switch (selectedOption) {
      case Constants.ADMIN_PANEL__MENU__USERS:
        return (
          <Users
            usersList={usersList}
            licensesInfo={licensesInfo}
            handleSetAdminPanelState={this.handleSetAdminPanelState}
            handleUpdateAdminPanelState={this.handleUpdateAdminPanelState}
            isLoading={isUsersLoading}
          />
        );
      case Constants.ADMIN_PANEL__MENU__PICKLISTS:
        return (
          <Picklists
            picklists={sortedPicklists}
            handleSetAdminPanelState={this.handleSetAdminPanelState}
            handleEditPicklist={this.handleEditPicklist}
            handleOpenPanel={this.openPanel}
            sortDirection={sortDirection}
            sortedProperty={sortedProperty}
            handleSortPicklists={this.handleSortProperty}
            isLoading={isPicklistsLoading}
          />
        );
      case Constants.ADMIN_PANEL__MENU__EDITED_PICKLIST:
        return (
          <PicklistAE
            picklistId={picklistId}
            openPanel={this.openPanel}
            handleSetAdminPanelState={this.handleSetAdminPanelState}
            activePanel={activePanel}
            handleSetAppState={handleSetAppState}
            picklistCustomerKey={picklistCustomerKey}
            navigateToMenu={this.navigateToMenu}
            isGettingValuesFromDEStarted={isGettingValuesFromDEStarted}
          />
        );
      case Constants.ADMIN_PANEL__MENU__NEW_PICKLIST:
        return (
          <PicklistAE
            picklistId={picklistId}
            openPanel={this.openPanel}
            handleSetAdminPanelState={this.handleSetAdminPanelState}
            activePanel={activePanel}
            handleSetAppState={handleSetAppState}
            navigateToMenu={this.navigateToMenu}
            isGettingValuesFromDEStarted={isGettingValuesFromDEStarted}
          />
        );
      case Constants.ADMIN_PANEL__SUBMENU__PREDEFINED_RELATIONS:
        return (
          <Relations
            relations={sortedRelations}
            handleSetAdminPanelState={this.handleSetAdminPanelState}
            handleEditRelation={this.handleEditRelation}
            handleOpenPanel={this.openPanel}
            sortDirection={sortDirection}
            sortedProperty={sortedProperty}
            handleSortRelations={this.handleSortProperty}
            isLoading={isRelationsLoading}
            dataSets={sortedDataSets}

          />
        );

      case Constants.ADMIN_PANEL__SUBMENU__CONTACT_ATTRIBUTES:
        return (
          <ContactAttributes
            contactAttributes={sortedContactAttributes}
            handleSetAdminPanelState={this.handleSetAdminPanelState}
            handleEditRelation={this.handleOpenContactRelation}
            handleOpenPanel={this.openPanel}
            sortDirection={sortDirection}
            sortedProperty={sortedProperty}
            handleSortRelations={this.handleSortProperty}
            isLoading={isContactAttributesLoading}
            dataSets={sortedDataSets}
            handleFetchContactAttributes={this.handleFetchContactAttributes}
          />
        );

      case Constants.ADMIN_PANEL__MENU__NEW_RELATION:
        return (
          <RelationPanel
            relationId={relationId}
            selectedRelation={selectedRelation}
            relations={sortedRelations}
            openPanel={this.openPanel}
            handleSetAdminPanelState={this.handleSetAdminPanelState}
            activePanel={activePanel}
            handleSetAppState={handleSetAppState}
            handleOpenPanel={this.openPanel}
            navigateToMenu={this.navigateToMenu}
            submenu={submenu}
          />
        );

      case Constants.ADMIN_PANEL__MENU__EDIT_RELATION:
        return (
          <RelationPanel
            relationId={relationId}
            selectedRelation={selectedRelation}
            relations={sortedRelations}
            openPanel={this.openPanel}
            handleSetAdminPanelState={this.handleSetAdminPanelState}
            activePanel={activePanel}
            handleSetAppState={handleSetAppState}
            relationDataSet={relationDataSet}
            navigateToMenu={this.navigateToMenu}
            submenu={submenu}
          />
        );

      case Constants.ADMIN_PANEL__MENU__DE_FOLDERS:
        return (
          <DataExtensionFolders
            key={submenu}
            dataExtensionType={submenu}
            handleNavigator={handleNavigator}
            settings={foldersSettings}
            loadingSettings={loadingFoldersSettings}
          />
        );

      case Constants.ADMIN_PANEL__MENU__DATA_SETS:
        return (
          <DataSets
            dataSets={sortedDataSets}
            openPanel={this.openPanel}
            handleSetAdminPanelState={this.handleSetAdminPanelState}
            handleEditDataSet={this.handleEditDataSet}
            sortDirection={sortDirection}
            sortedProperty={sortedProperty}
            activePanel={activePanel}
            handleSetAppState={handleSetAppState}
            isLoading={isDataSetsLoading}
            handleSortDataSets={this.handleSortProperty}
          />
        );

      case Constants.ADMIN_PANEL__MENU__GLOBAL_CUSTOM_VALUES__PANEL:
        return (
          <GlobalCustomValuesPanel
            globalCustomValues={sortedGlobalCustomValues}
            openPanel={this.openPanel}
            isLoading={isGlobalCustomValuesLoading}
            handleSetAdminPanelState={this.handleSetAdminPanelState}
            handleSort={this.handleSortProperty}
            sortDirection={sortDirection}
            sortedProperty={sortedProperty}
          />
        );

      case Constants.ADMIN_PANEL__MENU__FILTER_SETS:
        return (
          <FilterSets
            filterSets={sortedFilterSets}
            openPanel={this.openPanel}
            handleSetAdminPanelState={this.handleSetAdminPanelState}
            handleEditFilterSet={this.handleEditFilterSet}
            sortDirection={sortDirection}
            sortedProperty={sortedProperty}
            activePanel={activePanel}
            handleSetAppState={handleSetAppState}
            isLoading={isFilterSetsLoading}
            handleSortFilterSets={this.handleSortProperty}
            handleCheckEditOrDelete={this.handleCheckEditOrDelete}
          />
        );

      case Constants.ADMIN_PANEL__MENU__SETTINGS:
        return (
          <Settings
            notificationEmails={notificationEmails}
            dataRetentionSettings={dataRetentionSettings}
            handleCreateNotificationEmail={this.handleCreateNotificationEmail}
            handleDeleteNotificationEmail={this.handleDeleteNotificationEmail}
            handleAddOrUpdateDataRetentionSettings={this.handleAddOrUpdateDataRetentionSettings}
            handleAddOrUpdateSendRelationshipSettings={this.handleAddOrUpdateSendRelationshipSettings}
            handleSetAdminPanelState={this.handleSetAdminPanelState}
            handleUpdateAdminPanelState={this.handleUpdateAdminPanelState}
            dataRetentionSettingACDE={dataRetentionSettingACDE}
            sendRelationshipSettings={sendRelationshipSettings}
            handleAddOrUpdateACDEDataRetentionSettings={this.handleAddOrUpdateACDEDataRetentionSettings}
            isLoading={isSettingsLoading}
          />
        );

      case Constants.ADMIN_PANEL__MENU__NEW_DATA_SET:
      case Constants.ADMIN_PANEL__MENU_EDIT_DATA_SET:
        return (
          <Provider store={store}>
            <DataSetsPanel
              dataSetId={dataSetId}
              openPanel={this.openPanel}
              activePanel={activePanel}
              dataSets={sortedDataSets}
              selectedDataSet={selectedDataSet}
            />
          </Provider>
        );

      case Constants.ADMIN_PANEL__MENU__NEW_FILTER_SET:
      case Constants.ADMIN_PANEL__MENU_EDIT_FILTER_SET:
        return (
          <Provider store={store}>
            <FilterSetsPanel
              filterSetId={filterSetId}
              openPanel={this.openPanel}
              activePanel={activePanel}
              dataSets={sortedDataSets}
              filterSets={filterSets}
              picklists={picklists}
              selectedFilterSet={selectedFilterSet}
            />
          </Provider>
        );

      case Constants.ADMIN_PANEL__MENU__GLOBAL_CUSTOM_VALUE:
        return (
          <Provider store={store}>
            <GlobalCustomValue
              customValue={selectedCustomValue}
              openPanel={this.openPanel}
              globalCustomValuesNames={globalCustomValues?.map(value => value.name)}
            />
          </Provider>
        );

      case Constants.ADMIN_PANEL__MENU__AUDIT_LOGS:
        return (
          <AuditLogs />
        );
      case Constants.ADMIN_PANEL__MENU__DEEDEE_AI_SCOPE_DES:
        return (
          <DeedeeExtensions handleSetAppState={handleSetAppState} />
        );
      default:
        return null;
    }
  }

  /**
   * Set the name of current type of search.
   * Set the sorting direction
   * @param {string} property - name of the column
   * @param {string} sortDirection - sorting direction
   * @returns {void} Will setState for sortedProperty and sortDirection
   */
  handleSortProperty = (property, sortDirection) => {
    const { sortedProperty } = this.state;
    // When changing sort type, start always with ascending order

    this.setState({
      sortedProperty: property,
      sortDirection: property === sortedProperty ? sortDirection : Constants.SORT_DIRECTION__ASCENDING,
    });
  };

  /**
   * Function that handles hiding of banner model
   * @returns {void}
   */
  hideBannerModal = () => {
    this.setState({ showBanner: false });
  };

  /**
   * Create a notification email
   * @param {string} email - name of the column
   * @returns {void}
   */
  handleCreateNotificationEmail = async (email) => {
    try {
      await SettingsAPI.createNotificationEmail(
        { email },
        this.axiosCancelToken.token,
      );

      const notificationEmails = await SettingsAPI.getNotificationEmails(this.axiosCancelToken.token);

      this.setState({ notificationEmails });
    } catch (error) {
      this.handleSetAdminPanelState({ error });
    }
  };

  /**
   * Delete a notification email
   * @param {string} id - id of the notification email to delete
   * @returns {void}
   */
  handleDeleteNotificationEmail = async (id) => {
    try {
      await SettingsAPI.deleteNotificationEmail(
        id,
        this.axiosCancelToken.token,
      );

      const notificationEmails = await SettingsAPI.getNotificationEmails(this.axiosCancelToken.token);

      this.setState({ notificationEmails });
    } catch (error) {
      this.handleSetAdminPanelState({ error });
    }
  };

  /**
   * Add or create retention policy
   * @param {object} retentionPolicy - retention policy settings
   * @returns {void}
   */
  handleAddOrUpdateDataRetentionSettings = async (retentionPolicy) => {
    try {
      await SettingsAPI.addOrUpdateDataRetentionSettings(
        retentionPolicy,
        this.axiosCancelToken.token,
      );

      const dataRetentionSettings = await SettingsAPI.getDataRetentionSettings(this.axiosCancelToken.token);

      this.setState({ dataRetentionSettings });
    } catch (error) {
      this.handleSetAdminPanelState({ error });
    }
  };

  /**
   * Add or create retention policy for ACDE
   * @param {object} retentionPolicy - retention policy settings
   * @returns {void}
   */
  handleAddOrUpdateACDEDataRetentionSettings = async (retentionPolicy) => {
    try {
      await SettingsAPI.addOrUpdateACDEDataRetentionSettings(
        retentionPolicy,
        this.axiosCancelToken.token,
      );

      const dataRetentionSettingACDE = await SettingsAPI.getACDEDataRetentionSettings(this.axiosCancelToken.token);

      this.setState({ dataRetentionSettingACDE });
    } catch (error) {
      this.handleSetAdminPanelState({ error });
    }
  };

  /**
   * Add or create Send Relationship settings
   * @param {object} sendRelationship - retention policy settings
   * @returns {void}
   */
  handleAddOrUpdateSendRelationshipSettings = async (sendRelationship) => {
    try {
      await SettingsAPI.addOrUpdateSendRelationshipSettings(
        sendRelationship,
        this.axiosCancelToken.token,
      );

      const sendRelationshipSettings = await SettingsAPI.getSendRelationshipSettings(this.axiosCancelToken.token);

      this.setState({ sendRelationshipSettings });
    } catch (error) {
      this.handleSetAdminPanelState({ error });
    }
  };

  showEssentialsUpgradeModal = (feature) => {
    this.setState(prevState => ({
      featureAdvertModal: {
        ...prevState.featureAdvertModal,
        show: true,
        feature,
      },
    }));
  };

  cancelEssentialsUpgradeModal = () => {
    this.setState(prevState => ({
      featureAdvertModal: {
        ...prevState.featureAdvertModal,
        show: false,
        feature: '',
      },
    }));
  };

  render() {
    const {
      handleNavigator, orgInfo, featuresInfo, hasServer2ServerInstalledPackageInfo, hasImportedRelationsPermissionsInfo,
    } = this.props;

    const featurePicklist = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__PICKLISTS);
    const featurePredefinedRelations = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__PREDEFINED_RELATIONS);
    const featureDataSets = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__DATA_SETS);
    const featureFilterSets = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__FILTER_SETS);
    const featureFolderFilters = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__FOLDER_FILTERS);
    const featureGlobalCustomValues = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__GLOBAL_CUSTOM_VALUES);
    const featureAuditLogs = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__AUDIT_LOGS);
    const featureDeeDeeAI = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__DEEDEE_AI);
    const showImportedRelations = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__IMPORTED_RELATIONS);

    const hasServer2ServerInstalledPackage = Util.checkIsEnabledCookies(hasServer2ServerInstalledPackageInfo);
    const hasImportedRelationsPermissions = Util.checkIsEnabledCookies(hasImportedRelationsPermissionsInfo);

    const {
      activePanel, loading, submenu, isGettingValuesFromDEStarted, featureAdvertModal, showBanner,
    } = this.state;

    const folderOptions = [
      { title: 'Available Data Extensions', value: Constants.ADMIN_PANEL__SUBMENU__AVAILABLE_DES },
      { title: 'Target Data Extensions', value: Constants.ADMIN_PANEL__SUBMENU__TARGET_DES },
    ];

    const relationOptions = [
      {
        title: 'Custom Relations',
        value: Constants.ADMIN_PANEL__SUBMENU__PREDEFINED_RELATIONS,
        feature: Constants.FEATURE__PREDEFINED_RELATIONS,
        featureAvailable: featurePredefinedRelations,
      },
      {
        title: 'Imported Relations',
        value: Constants.ADMIN_PANEL__SUBMENU__CONTACT_ATTRIBUTES,
        feature: Constants.FEATURE__IMPORTED_RELATIONS,
        featureAvailable: showImportedRelations,
        isDisabled: !hasServer2ServerInstalledPackage || !hasImportedRelationsPermissions,
        hasTooltip: !hasServer2ServerInstalledPackage || !hasImportedRelationsPermissions,
        tooltipType: hasServer2ServerInstalledPackage ?
          Constants.TOOLTIP_TYPE__UPDATE_S2S_PACKAGE :
          Constants.TOOLTIP_TYPE__S2S_PACKAGE_NOT_INSTALLED,
      },
    ];

    const isRelationMenuActive = [Constants.ADMIN_PANEL__SUBMENU__PREDEFINED_RELATIONS,
      Constants.ADMIN_PANEL__SUBMENU__CONTACT_ATTRIBUTES,
      Constants.ADMIN_PANEL__MENU__EDIT_RELATION].includes(activePanel);

    const isFilterSetMenuActive =
      [Constants.ADMIN_PANEL__MENU_EDIT_FILTER_SET, Constants.ADMIN_PANEL__MENU__NEW_FILTER_SET,
        Constants.ADMIN_PANEL__MENU__FILTER_SETS].includes(activePanel);

    const isDataSetMenuActive = [Constants.ADMIN_PANEL__MENU__DATA_SETS,
      Constants.ADMIN_PANEL__MENU__NEW_DATA_SET,
      Constants.ADMIN_PANEL__MENU_EDIT_DATA_SET].includes(activePanel);

    if (loading) {
      return (
        <LoadingModal
          closeModal={() => handleNavigator(Constants.NAVIGATION__OVERVIEW)}
          loadingText="Admin Panel is loading..."
          id="loadingmodal-wrapper"
        />
      );
    }

    return (
      <Provider store={store}>
        <div className="admin-container">
        {showBanner && orgInfo?.edition === Constants.ORGANISATION__EDITION__ESSENTIALS && (
              <Banner onclickHideBannerModal={this.hideBannerModal} />
        )}
        <Navbar />
        <div className="AdminPanel">
          <div
            className={classNames(
              'sidenav',
              { 'disabled-navigation': isGettingValuesFromDEStarted },
            )}>
            <div
              className="backArrow slds-p-around_small"
              onClick={() => !isGettingValuesFromDEStarted && handleNavigator(Constants.NAVIGATION__OVERVIEW)}
            >
              <i className="fas fa-arrow-left" />
              <span>Back to Home</span>
            </div>
            <h1 className="slds-text-heading_large slds-m-bottom_medium">Administration</h1>

            {/* Each div here should set the state property
          activePanel to its own option name when clicked */}
            <div
              id="usersOption"
              className={activePanel === Constants.ADMIN_PANEL__MENU__USERS ?
                'selected-option slds-p-around_medium menu-option' :
                'slds-p-around_medium menu-option'}
              onClick={async () => {
                if (!isGettingValuesFromDEStarted) {
                  await this.setState({ activePanel: Constants.ADMIN_PANEL__MENU__USERS });
                  await this.openPanel(Constants.ADMIN_PANEL__MENU__USERS);
                }
              }}
            >
              Users
            </div>

            <div
              id="picklistsOption"
              className={classNames(
                'slds-p-around_medium menu-option',
                {
                  'selected-option': activePanel === Constants.ADMIN_PANEL__MENU__PICKLISTS ||
                    activePanel === Constants.ADMIN_PANEL__MENU__EDITED_PICKLIST ||
                    activePanel === Constants.ADMIN_PANEL__MENU__NEW_PICKLIST,
                  'feature-unavailable': !featurePicklist,
                },
              )}
              onClick={() => this.handleOpenPicklistPanel()}
            >
              <FeatureTitle
                title="Picklists"
                featureEnabled={featurePicklist}
                feature={Constants.FEATURE__PICKLISTS}
                />
            </div>

            {featurePredefinedRelations && (
              <div
                id="RelationsOption"
                className={isRelationMenuActive ?
                  'selected-option slds-p-around_medium relations' :
                  'slds-p-around_medium relations'}
              >
                Relations
                <br />
                {
                  relationOptions.map(option => (
                    <div
                      key={option.value}
                      id={option.value}
                      className={classNames({
                        'relations-submenu': true,
                        active: activePanel === option.value,
                        'relation-submenu-disabled': option.isDisabled,
                        'feature-unavailable': !option.featureAvailable,
                      })}
                      onClick={() => this.handleClickRelationOption(option)}
                    >
                      <span className="display-flex">
                        <FeatureTitle
                          title={option.title}
                          feature={option.feature}
                          featureEnabled={option.featureAvailable} />
                        {option.hasTooltip &&
                          (<Tooltip
                            nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
                            type={option.tooltipType}
                          />)}
                      </span>
                    </div>
                  ))
                }
              </div>
            )}

            <div
              id="DataSets"
              className={classNames(
                'slds-p-around_medium menu-option',
                {
                  'selected-option': isDataSetMenuActive,
                  'feature-unavailable': !featureDataSets,
                },
              )}
              onClick={async () => {
                if (featureDataSets) {
                  if (!isGettingValuesFromDEStarted) {
                    await this.setState({ activePanel: Constants.ADMIN_PANEL__MENU__DATA_SETS });
                    await this.openPanel(Constants.ADMIN_PANEL__MENU__DATA_SETS);
                  }
                } else {
                  this.showEssentialsUpgradeModal(Constants.FEATURE__DATA_SETS);
                }
              }}
            >
              <FeatureTitle
                title="Data Sets"
                feature={Constants.FEATURE__DATA_SETS}
                featureEnabled={featureDataSets} />
            </div>

            <div
              id="globalCustomValuesOption"
              className={classNames(
                'slds-p-around_medium menu-option',
                {
                  'selected-option': activePanel === Constants.ADMIN_PANEL__MENU__GLOBAL_CUSTOM_VALUES__PANEL ||
                    activePanel === Constants.ADMIN_PANEL__MENU__GLOBAL_CUSTOM_VALUE,
                  'feature-unavailable': !featureGlobalCustomValues,
                },
              )}
              onClick={() => this.handleOpenGlobalCustomValuesPanel()}
            >
              <FeatureTitle
                title="Shared Custom Values"
                feature={Constants.FEATURE__GLOBAL_CUSTOM_VALUES}
                featureEnabled={featureGlobalCustomValues} />
            </div>

            <div
              id="filterSets"
              className={classNames(
                'slds-p-around_medium menu-option',
                {
                  'selected-option': isFilterSetMenuActive,
                  'feature-unavailable': !featureFilterSets,
                },
              )}
              onClick={
                () => featureFilterSets ?
                  this.handleOpenFilterSetPanel() :
                  this.showEssentialsUpgradeModal(Constants.FEATURE__FILTER_SETS)
              }
            >
              <FeatureTitle
                title="Filter Sets"
                feature={Constants.FEATURE__FILTER_SETS}
                featureEnabled={featureFilterSets} />
            </div>

            {featureFolderFilters && (
              <div
                id="DEFolders"
                className={activePanel === Constants.ADMIN_PANEL__MENU__DE_FOLDERS ?
                  'selected-option slds-p-around_medium de-folders' :
                  'slds-p-around_medium de-folders'}
              >
                <div style={{ position: 'relative' }}>
                  Data Extension Folders
                  <div className="guidance-parent-admin-folders">
                    <GuidanceTip tipId="dataextension-folders-tip" toolTipPosition="right" />
                  </div>
                </div>
                <br />
                {
                  folderOptions.map(option => (
                    <div
                      key={option.value}
                      id={option.value}
                      className={(activePanel === Constants.ADMIN_PANEL__MENU__DE_FOLDERS &&
                        submenu === option.value) ?
                        'active de-folders-submenu' :
                        'de-folders-submenu'}
                      onClick={async () => {
                        if (!isGettingValuesFromDEStarted) {
                          await this.openPanel(Constants.ADMIN_PANEL__MENU__DE_FOLDERS);
                          await this.setState({
                            activePanel: Constants.ADMIN_PANEL__MENU__DE_FOLDERS,
                            submenu: option.value,
                          });
                        }
                      }}
                    >
                      {option.title}
                    </div>
                  ))
                }
              </div>
            )}

            <div
              id="notificationsOption"
              className={classNames(
                {
                  'selected-option slds-p-around_medium menu-option':
                    activePanel === Constants.ADMIN_PANEL__MENU__SETTINGS,
                },
                { 'slds-p-around_medium menu-option': !(activePanel === Constants.ADMIN_PANEL__MENU__SETTINGS) },
              )}
              onClick={() => {
                this.setState({ activePanel: Constants.ADMIN_PANEL__MENU__SETTINGS });
                this.openPanel(Constants.ADMIN_PANEL__MENU__SETTINGS);
              }}
            >
              Settings
            </div>

            <div
              id="notificationsOption"
              className={classNames(
                {
                  'selected-option slds-p-around_medium menu-option':
                    activePanel === Constants.ADMIN_PANEL__MENU__AUDIT_LOGS,
                  'feature-unavailable': !featureAuditLogs,

                },
                { 'slds-p-around_medium menu-option': !(activePanel === Constants.ADMIN_PANEL__MENU__AUDIT_LOGS) },
              )}
              onClick={
                () => featureAuditLogs ?
                  this.handleOpenAuditLogsPanel() :
                  this.showEssentialsUpgradeModal(Constants.FEATURE__AUDIT_LOGS)
              }
            >
              <FeatureTitle
                feature={Constants.FEATURE__AUDIT_LOGS}
                title="Audit Logs"
                featureEnabled={featureAuditLogs} />
            </div>

            {/** DEEDEE AI DATA EXTENSIONS */}
            <div
              id="notificationsOption"
              className={classNames(
                {
                  'selected-option slds-p-around_medium menu-option':
                    activePanel === Constants.ADMIN_PANEL__MENU__DEEDEE_AI_SCOPE_DES,
                  'feature-unavailable': !featureDeeDeeAI,

                },
                {
                  'slds-p-around_medium menu-option':
                !(activePanel === Constants.ADMIN_PANEL__MENU__DEEDEE_AI_SCOPE_DES),
                },
              )}
              onClick={
                () => featureDeeDeeAI ?
                  this.handleOpenDeedeeAIExtensionsPanel() :
                  this.showEssentialsUpgradeModal(Constants.FEATURE__DEEDEE_AI)
              }
            >
              <FeatureTitle
                feature={Constants.FEATURE__DEEDEE_AI}
                title="DeedeeAI Data Extensions"
                featureEnabled={featureDeeDeeAI} />
            </div>

            <div className="feature-advert-modals">
                <FeatureAdvertContainer
                  feature={featureAdvertModal.feature}
                  showFeatureModal={featureAdvertModal.show}
                  handleCancel={this.cancelEssentialsUpgradeModal}
                />
            </div>
          </div>

          <div className="main">
            {this.handleRenderOptionSelected(activePanel)}
          </div>
        </div>
        </div>
      </Provider>
    );
  }
}

AdminPanel.propTypes = {
  /**
   * handleNavigator prop is passed from App.js and it helps to navigate between Overview and Selection
   */
  handleNavigator: PropTypes.func.isRequired,
  /**
   * handleSetAppState prop is passed from App.js (it is used to be passed to PicklistAE)
   */
  handleSetAppState: PropTypes.func.isRequired,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
  /**
   * Org info from cookie
   */
  orgInfo: PropTypes.object,
  /**
   * hasServer2ServerInstalledPackage info from cookie
   */
  hasServer2ServerInstalledPackageInfo: PropTypes.string,
  /**
   * hasImportedRelationsPermissions info from cookie
   */
  hasImportedRelationsPermissionsInfo: PropTypes.string,
};

export default connect(
  mapStateToProps(['orgInfo', 'featuresInfo',
    'hasServer2ServerInstalledPackageInfo', 'hasImportedRelationsPermissionsInfo']),
  null,
  null,
  { pure: false },
)(AdminPanel);
