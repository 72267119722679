import React, { useState, useEffect } from 'react';
import { Popover, Button } from '@salesforce/design-system-react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ReactDragListView from 'react-drag-listview';
import { connect } from 'react-redux';

import Constants from '../../constants/constants';
import SwalUtil from '../../utils/swal/swalUtil';
import Chat from '../../icons_v2/chat.svg';
import Help from '../../icons_v2/help.svg';
import Book from '../../icons_v2/book-open.svg';
import Video from '../../icons_v2/video.svg';
import Network from '../../icons_v2/network.svg';
import Checkbox from '../shared_v2/Checkbox/Checkbox';
import UsersAPI from '../../api/users';
import './styles.scss';
import mapStateToProps from '../../mapStateToProps';

const HelpMenu = ({
  userPreferences,
  handleNavigator,
  navigator,
  handleFolderClicked,
  handleSetOverviewState,
  selections,
  isOverview,
  overviewSection,
  userInfo,
}) => {
  const [isColumnsClicked, setIsColumnsClicked] = useState(false);
  const [checkedColumns, setCheckedColumns] = useState([]);

  const hasReadOnlyAccess = userInfo?.hasReadOnlyAccess;

  const axiosToken = axios.CancelToken.source();

  /**
   * Update user's preferences in the DB
   * @param {Object} preferences - user preferences object
   * @returns {void}
   */
  const updatePreferences = async (preferences) => {
    try {
      await UsersAPI.updatePreferences(
        preferences,
        axiosToken.token,
      );
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const removeOrAddColumn = (column, useInUseEffect, isCheckBoxClicked) => {
    const statusColumn = document.getElementsByClassName(`des-lib-datatable-th ${column.name}`)[0];
    const statusCell = document.getElementsByClassName(`des-lib-datatable-td ${column.name}`);

    const checkedColumnsCopy = useInUseEffect ?
      [...userPreferences.selectionOverviewColumnsToBeShown] :
      [...checkedColumns];

    const columnIndex = checkedColumnsCopy.findIndex(obj => obj.name === column.name);

    if ((column.isChecked && !isCheckBoxClicked) ||
      (isCheckBoxClicked && !column.isChecked)) {
      if (!useInUseEffect) {
        statusColumn.style.display = '';

        for (let i = 0; i < statusCell.length; i += 1) {
          statusCell[i].style.display = '';
        }

        checkedColumnsCopy[columnIndex].isChecked = true;
      }
    } else {
      if (statusColumn) statusColumn.style.display = 'none';

      for (let i = 0; i < statusCell.length; i += 1) {
        statusCell[i].style.display = 'none';
      }

      checkedColumnsCopy[columnIndex].isChecked = false;
    }

    if (!useInUseEffect) {
      setCheckedColumns(checkedColumnsCopy);

      try {
        updatePreferences({ selectionOverviewColumnsToBeShown: checkedColumnsCopy });
      } catch (error) {
        if (!axios.isCancel(error)) handleSetOverviewState({ error });
      }
    }
  };

  useEffect(() => {
    if (userPreferences.selectionOverviewColumnsToBeShown) {
      setCheckedColumns(
        [
          ...userPreferences.selectionOverviewColumnsToBeShown,
        ],
      );
    }
  }, [userPreferences]);

  useEffect(() => {
    if (navigator === Constants.NAVIGATION__OVERVIEW) {
      const interval = setInterval(() => {
        const statusColumn = document.getElementsByClassName('des-lib-datatable-th Status')[0];

        if (userPreferences.selectionOverviewColumnsToBeShown && statusColumn) {
          clearInterval(interval);

          userPreferences.selectionOverviewColumnsToBeShown.forEach((column) => {
            removeOrAddColumn(column, true, false);
          });
        }

        if (!userPreferences.selectionOverviewColumnsToBeShown && statusColumn) {
          clearInterval(interval);
        }
      }, 500);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const statusColumn = document.getElementsByClassName('des-lib-datatable-th Status')[0];

    if (userPreferences.selectionOverviewColumnsToBeShown && statusColumn) {
      userPreferences.selectionOverviewColumnsToBeShown.forEach((column) => {
        removeOrAddColumn(column, true, false);
      });
    }

    // eslint-disable-next-line
  }, [selections]);

  /**
   * Handler for the onClick event on the admin button
   * @returns {void}
   */
  const handleClickOnAdminButton = async () => {
    const confirmationNeededFor = [
      Constants.NAVIGATION__SELECTION_CRITERIA,
      Constants.NAVIGATION__TARGET_DEFINITION,
      Constants.NAVIGATION__PREVIEW,
    ];

    if (confirmationNeededFor.includes(navigator)) {
      const res = await SwalUtil.fire({
        title: 'Confirmation needed',
        message: `Are you sure you want to go to the Admin Panel ?
        Unsaved changes will be lost.`,
        options: {
          showCancelButton: true,
          allowOutsideClick: false,
        },
      });

      if (res.value) {
        handleNavigator(Constants.NAVIGATION__ADMIN_PANEL);
      }
    } else {
      handleNavigator(Constants.NAVIGATION__ADMIN_PANEL);
    }
  };

  /**
   * Renders the correct link depending on where we are in the App
   * @returns {void}
   */
  const relevantSupportLink = () => {
    const supportLink = 'https://support.deselect.com/hc/en-us';

    switch (navigator) {
      // OVERVIEW
      case Constants.NAVIGATION__OVERVIEW:
        return `${supportLink}/sections/360000730878-Step-0-Selections-Overview`;
      // SELECTION > CRITERIA
      case Constants.NAVIGATION__SELECTION_CRITERIA:
        return `${supportLink}/sections/360002311818-Step-1-Selection-Criteria`;
      // SELECTION > TARGET DEFINITION
      case Constants.NAVIGATION__TARGET_DEFINITION:
        return `${supportLink}/sections/360002311838-Step-2-Target-Definition`;
      // SELECTION > PREVIEW
      case Constants.NAVIGATION__PREVIEW:
        return `${supportLink}/sections/360002291697-Step-3-Preview-and-Run`;
      default:
        return supportLink;
    }
  };

  /**
   * function passed to the ReactDragListView component to change the order of the selection overview columns
   * based on the user drag and dropping actions
   * @param {integer} fromIndex - Index where the rule is coming from
   * @param {integer} toIndex - Index where the rule is coming to
   * @returns {void}
   */
  const onDragEnd = (fromIndex, toIndex) => {
    const newColumns = [...checkedColumns];

    const draggedColumn = newColumns.splice(fromIndex, 1)[0];

    newColumns.splice(toIndex, 0, draggedColumn);

    const rows = document.querySelectorAll('#selection-overview-table > thead > tr');
    const cells = document.querySelectorAll('#selection-overview-table > tbody > tr');

    let toIndexIncrement = 2;

    if (fromIndex > toIndex) toIndexIncrement = 1;

    for (let i = 0; i < rows.length; i += 1) {
      rows[i].insertBefore(rows[i].children[fromIndex + 1], rows[i].children[toIndex + toIndexIncrement]);
    }

    for (let i = 0; i < cells.length; i += 1) {
      cells[i].insertBefore(cells[i].children[fromIndex + 1], cells[i].children[toIndex + toIndexIncrement]);
    }

    setCheckedColumns(newColumns);

    try {
      updatePreferences({ selectionOverviewColumnsToBeShown: newColumns });
    } catch (error) {
      if (!axios.isCancel(error)) handleSetOverviewState({ error });
    }
  };

  const menuItems = (
    <div className="des-help-menu">
      {isColumnsClicked ?
        (
          <div>
            <div
              className="back-btn"
              onClick={() => setIsColumnsClicked(false)}
            >
              <svg
                className="slds-icon-size"
                aria-hidden="true"
                id="back-btn-icon"
              >
                <use xlinkHref="assets/icons/utility-sprite/svg/symbols.svg#chevronleft" />
              </svg>
              <span className="back-label">
                Back
              </span>
            </div>
            <div className="des-popover-menu">
              <ReactDragListView nodeSelector="div" handleSelector="#drag" onDragEnd={onDragEnd}>
                {checkedColumns.map((column) => {
                  if (overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS &&
                    column.name === 'Template') return null;

                  return (
                    <div
                      key={column.name}
                      className="column-item"
                    >
                      <Checkbox
                        formElementClassName="folder-selection-checkbox"
                        name={column.name}
                        id={column.name}
                        checked={column.isChecked}
                        onChange={() => removeOrAddColumn(column, false, true)}
                        label={column.name}
                      />
                      {/* DRAG BUTTON */}
                      <Button
                        title="Drag this card to change its order"
                        id="drag"
                        className="drag-and-drop"
                      >
                        <svg className="slds-button__icon" aria-hidden="true">
                          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#drag_and_drop" />
                        </svg>
                      </Button>
                    </div>
                  );
                })}
              </ReactDragListView>
            </div>
          </div>
        ) :
        (
          <ul className="des-popover-menu">
            <li
              className="des-popover-menu__item"
              role="presentation"
              onClick={() => window.open('https://support.deselect.com/hc/en-us/requests/new', '_blank')}
            >
              <img
                className="des-popover-menu__item_icon"
                src={Chat}
                alt="icon"
              />
              <a
                href="https://support.deselect.com/hc/en-us/requests/new"
                role="menuitem"
                tabIndex="0"
                className="centered-items"
                target="_blank"
                rel="noreferrer"
              >
                <span className="slds-truncate" title="Message Support">
                  Message Support
                </span>
              </a>
            </li>
            <li
              className="des-popover-menu__item"
              role="presentation"
              onClick={() => window.open(relevantSupportLink(), '_blank')}
            >
              <img
                className="des-popover-menu__item_book"
                src={Book}
                alt="icon"
              />
              <a
                href={relevantSupportLink()}
                target="_blank"
                rel="noopener noreferrer"
                role="menuitem"
                tabIndex="0"
              >
                <span className="slds-truncate" title="Help & documentation">
                  Help & documentation
                </span>
              </a>
            </li>
            {
              isOverview && (
                <li
                  className="des-popover-menu__item"
                  role="presentation"
                  onClick={() => handleFolderClicked('')}
                >
                  <img
                    className="des-popover-menu__item_icon"
                    src={Help}
                    alt="icon"
                  />
                  <a
                    href="#!"
                    rel="noopener noreferrer"
                    role="menuitem"
                    tabIndex="0"
                  >
                    <span className="slds-truncate" title="Uncategorized Selections">
                      Uncategorized Selections
                    </span>
                  </a>
                </li>
              )
            }
            <li
              className="des-popover-menu__item"
              role="presentation"
              onClick={() => window.open('https://deselect.com/contact/', '_blank')}
            >
              <img
                className="des-popover-menu__item_icon"
                src={Video}
                alt="icon"
              />
              <a
                href="https://deselect.com/contact/"
                target="_blank"
                rel="noopener noreferrer"
                role="menuitem"
                tabIndex="0"
              >
                <span className="slds-truncate" title="Contact Sales">
                  Contact Sales
                </span>
              </a>
            </li>
            <div className="des-popover-menu__divider" />
            <li
              className="des-popover-menu__item_trust bordered"
              role="presentation"
              onClick={() => window.open('https://status.deselect.com/', '_blank')}
            >
              <img
                className="des-popover-menu__item_trust"
                src={Network}
                alt="icon"
              />
              <a
                href="https://status.deselect.com/"
                target="_blank"
                rel="noopener noreferrer"
                role="menuitem"
                tabIndex="0"
              >
                <span className="slds-truncate" title="Trust Status Page">
                  Trust Status Page
                </span>
              </a>
            </li>
            <div className="des-popover-menu__divider" />
            <li
              className="des-popover-menu__item_other_links"
              role="presentation"
              onClick={() => window.open('https://deselect.com/category/releases/', '_blank')}
            >
              <a
                href="https://deselect.com/category/releases/"
                target="_blank"
                rel="noopener noreferrer"
                role="menuitem"
                tabIndex="0"
              >
                <span className="slds-truncate" title="What's New">
                  What&rsquo;s new
                </span>
              </a>
            </li>
            <li
              className="des-popover-menu__item_other_links"
              role="presentation"
              onClick={() => window.open('https://deselect.com/', '_blank')}
            >
              <a
                href="https://deselect.com/"
                target="_blank"
                rel="noopener noreferrer"
                role="menuitem"
                tabIndex="0"
              >
                <span className="slds-truncate" title="About DESelect">
                  About DESelect
                </span>
              </a>
            </li>
            {
              userInfo !== undefined &&
              userInfo.isAdmin &&
              !hasReadOnlyAccess && (
                <li
                  className="des-popover-menu__item_other_links"
                  role="presentation"
                  onClick={handleClickOnAdminButton}
                >
                  <div
                    role="menuitem"
                    tabIndex="0"
                    className="slds-truncate des-admin-panel__trigger"
                    title="Admin Panel"
                  >
                    Admin Panel
                  </div>
                </li>
              )
            }

            {
              isOverview && (
                <li
                  className="des-popover-menu__item_other_links"
                  role="presentation"
                  onClick={() => setIsColumnsClicked(true)}
                >
                  <div
                    role="menuitem"
                    tabIndex="0"
                    className="slds-truncate des-admin-panel__trigger"
                    title="Columns"
                  >
                    Columns
                  </div>
                </li>
              )
            }
          </ul>
        )}

      <div className="des-help-menu__footer">
        <p className="des-help-menu__footer-text">Version 2.2.4</p>
        <p className="des-help-menu__footer-text">
          Updated 1 day ago
        </p>
      </div>
    </div>
  );

  return (
    <div className="des-help-menu-container">
      <Popover
        body={menuItems}
        id="des-help-menu-popover"
        align="top"
        style={{ width: '210px' }}
      >
        <Button
          className="des-help-menu-button"
          iconCategory="utility"
          iconName="help"
          iconSize="medium"
          iconVariant="container"
          variant="icon"
        />
      </Popover>
    </div>
  );
};

HelpMenu.propTypes = {
  /**
   * HandleNavigator prop is passed from App.js and it helps to navigate between the different screen of the App
   */
  handleNavigator: PropTypes.func.isRequired,
  /**
   * User preferences
   */
  userPreferences: PropTypes.object,
  /**
   * Navigator prop is passed from App.js and it determines to know where we where in the App
   */
  navigator: PropTypes.string.isRequired,
  /**
   * It helps to handle with click on some folder
   */
  handleFolderClicked: PropTypes.func.isRequired,
  /**
   * Function setting state of overview.js
   */
  handleSetOverviewState: PropTypes.func,
  /**
   * selections which are kept in the db
   */
  selections: PropTypes.instanceOf(Object).isRequired,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(HelpMenu);
