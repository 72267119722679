import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Arrow from '../../../gif/arrow.gif';
import ClosedFolder from '../../../icons_v2/closed_folder.svg';
import { ReactComponent as StarFull } from '../../../icons_v2/star_full.svg';
import Spinner from '../../shared_v2/Spinner/Spinner';
import Folders from '../Folders/Folders';
import './styles.scss';
import Constants from '../../../constants/constants';
import UpgradeBadge from '../../shared/UpgradeBadge/UpgradeBadge';
import Tooltip from '../../shared/Tooltip/Tooltip';
import { featureAdvertExists } from '../../shared/Feature/featureUtil';
import Util from '../../../util';

const NewSelectionFolders = ({
  hideFolders, blurDrop, offsetTop, filterFolderId, rightClick, handleSetOverviewState,
  refreshSelections, openAllFolders, draggedSelectionId, nestedFolders, folderId,
  handleFolderClicked, dropOnFolder, folderBorderDragOver, startDraggingFolder,
  draggedFolderId, openSelections, handleHideFolders, loadingFolders, showLoadingModal, isReadOnly,
  handleSetAppState, folderSettings, allFolders, isWaterfall,
  handleOpenArchiveSelectionModal, hideArchivedSelections, isArchived,
  featureSelectionFolders, showEssentialsUpgradeModal, starOrRemoveStarOnFolder,
}) => {
  /**
   * Handles click on the folder
   * @param {array} foldersInOverview - array with folders in Overview
   * @param {string} folderID - folder id
   * @returns {void}
   */
  const handleClickOnFolder = (foldersInOverview, folderID) => {
    // for folders in Overview
    if (foldersInOverview) {
      // if folderID is null, that means all folders are selected
      if (folderID === null) {
        handleSetOverviewState({
          openSelections: false,
        });
      }

      if (folderID === '') {
        // if folderID is '', that means uncategorized folders are selected
        handleSetOverviewState({
          openSelections: !openSelections,
        });
      }
    }
    // dispatch an action for selecting a folder by ID
    handleFolderClicked(folderID);
  };

  /**
   * Handles click on the star icon on the folder
   * @param {object} e - click event
   * @param {string} folderId - folder id
   * @returns {void}
   */
  const handleClickStarOnFolder = (e, folderId) => {
    e.stopPropagation();
    starOrRemoveStarOnFolder(folderId, false);
  };

  /**
   * Returns the status of all folders - closed, open or null
   * @returns {string|null} open, close or null if all folders do not meet the condition
   */
  const allFoldersStatus = () => {
    const { open } = folderSettings || {};

    // get all folders with children
    const allFoldersWithChildren = allFolders?.filter(folder => folder?.children?.length ? folder._id : null);

    // check if all folders are open
    const allOpen = allFoldersWithChildren.every(folder => folder.state === open);

    // If all folders are open, return open
    if (allOpen) {
      return Constants.SELECTION_FOLDER_SETTINGS__OPEN__VALUE;
    }

    // If all folders are closed or not all are open, return closed
    return Constants.SELECTION_FOLDER_SETTINGS__CLOSED__VALUE;
  };

  const selectionFoldersClassName = classNames(
    'selection-folders-wrapper-v2',
    {
      'hide-folders': hideFolders,
      'in-waterfall': isWaterfall,
    },
  );

  const surroundingFoldersClassName = classNames(
    'surrounding_folders',
    {
      'full-height': featureSelectionFolders,
      'disabled-folders': !featureSelectionFolders,
    },
  );

  /**
   * Make border on drag over
   * @param {object} e - event
   * @param {boolean} border - If border exists or not
   * @returns {void}
   */
  const makeBorder = (e, border) => {
    const { target: { dataset: { id }, nodeName } } = e || {};

    // When dragging a folder to itself, dont add border to dragged folder
    if (draggedFolderId && draggedFolderId === id) return;

    // If selection or folder is dragged and is over the folder, add the border
    if (nodeName.toString().toLowerCase() === 'span') {
      e.target.className = classNames(
        'staticFolder',
        {
          /* eslint-disable quote-props */
          // when the drop target is not a selected folder
          'border_folder': border && folderBorderDragOver && !isArchived,
          'mark_all': id === filterFolderId,
        },
      );
    }
  };

  /**
   * className for uncategorized folder container
   */
  const uncategorizedFolderContainerClassName = classNames(
    'uncategorized',
    'staticFolder',
    {
      'mark_all': filterFolderId === '',
      disabledFolder: !featureSelectionFolders,
    },
  );

  /**
   * className for favorite folder container
   * @param {string} id - folder id
   * @returns {string} className
   */
  const favoriteFolderContainerClassName = id => classNames(
    'uncategorized',
    'staticFolder',
    {
      'mark_all': filterFolderId === id,
      disabledFolder: !featureSelectionFolders,
    },
  );

  /**
   * className for All Selections folder container
   */
  const allSelectionsContainerClassName = classNames(
    {
      'mainFolder': true,
      'mark_all': filterFolderId === null,
    },
  );

  /**
   * className for uncategorized folder title
   */
  const uncategorizedFolderTitleClassName = classNames(
    'folder_element',
    'mainFolderText',
    { 'blur': draggedSelectionId },
  );

  /**
   * className for archived selections folder title
   */
  const archivedSelectionsFolderTitleClassName = classNames(
    'folder_element',
    {
      'blur': draggedSelectionId && isArchived,
    },
  );

  /**
   * Handle onDrop event
   * @param {object} selectionId - Dragged selection Id
   * @param {object} e - event
   * @returns {void}
   */
  const onDropHandler = (selectionId, e) => {
    const { target: { dataset: { id } } } = e || {};

    handleOpenArchiveSelectionModal(selectionId, id);
  };

  /**
   * Returns class name for the archive folder container
   * @returns {string} className
   */
  const archiveFolderContainerClassName = () => classNames(
    'archiveFolder',
    'staticFolder',
    {
      'mark_all': filterFolderId === 'archivedSelectionFolderId',
      'disabledFolder': !featureSelectionFolders,
    },
  );

  const favoriteFolders = allFolders?.filter(folder => folder.isFavorite) || [];

  return (
    <div className={selectionFoldersClassName}>
      <div
        className={`overview_folders_wrapper
                      ${hideFolders && 'hide'}
                      ${blurDrop && 'blur'}`}
        style={{ height: `calc(100vh - 36px - ${offsetTop}px)` }}
      >
        {loadingFolders && !showLoadingModal ?
          (
            <div className="folder-loading-spinner">
              <Spinner size={Constants.SPINNER__SIZE__MEDIUM} assistiveText="Loading..." />
            </div>
          ) :
          (
            <div
              className={surroundingFoldersClassName}
              onClick={featureSelectionFolders ?
                null :
                () => showEssentialsUpgradeModal(Constants.FEATURE__FOLDERS)}
            >

              <div className="overlay-wrap" />
              <div className="disabled-feature-message">
                {
                  !featureSelectionFolders &&
                  (featureAdvertExists(Constants.FEATURE__FOLDERS) ?
                    <UpgradeBadge /> :
                    <Tooltip
                      align={Constants.SLDS_TOOLTIP_POSITION__TOP_RIGHT}
                      type={Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE}
                    />)
                }
              </div>
              <div className="folders-title-container">
                <h3 className="folders-title">
                  Favorites
                </h3>
              </div>
              <div
                className={
                  classNames(
                    'created_folders favorites-folders',
                    {
                      'open': openAllFolders,
                      'disabled-folders': !featureSelectionFolders,
                    },
                  )
                }
              >

                {
                  favoriteFolders.map((folder, index) => (
                    <div key={index}>
                      <span
                        className={favoriteFolderContainerClassName(folder._id)}
                        onClick={() => handleClickOnFolder(handleSetOverviewState && refreshSelections, folder._id)}
                        onContextMenu={e => e.preventDefault()}
                      >
                        <StarFull
                          className="des-star-icon"
                          title="Remove from favorites"
                          onClick={e => handleClickStarOnFolder(e, folder._id)}
                        />
                        <p
                          className={uncategorizedFolderTitleClassName}
                          onMouseDown={e => e.preventDefault()}
                          title={folder.name}
                        >
                          {Util.abbreviate(folder.name, 25)}
                        </p>
                      </span>
                    </div>
                  ))
                }

              </div>
              <div className="folders-title-container">
                <h3 className="folders-title">
                  Folders
                </h3>
              </div>
              <div
                className={allSelectionsContainerClassName}
                onContextMenu={e => rightClick && rightClick(e, '0')}
              >
                <span
                  className="staticFolder all-selections-folder-v2"
                  onClick={() => handleClickOnFolder(handleSetOverviewState && refreshSelections, null)}
                >
                  <p
                    className={uncategorizedFolderTitleClassName}
                    onMouseDown={e => e.preventDefault()}
                    title="All Selections"
                  >
                    All Selections
                  </p>
                </span>
              </div>
              <div
                className={
                  classNames(
                    'created_folders',
                    {
                      'open': openAllFolders,
                      'disabled-folders': !featureSelectionFolders,
                    },
                  )
                }
                style={featureSelectionFolders ?
                  { height: '' } :
                  { height: 'auto' }}
              >
                <Folders
                  folders={nestedFolders}
                  openAllFolders={openAllFolders}
                  id={folderId}
                  handleFolderClicked={handleFolderClicked}
                  rightClick={rightClick}
                  dropOnFolder={dropOnFolder}
                  folderBorderDragOver={folderBorderDragOver}
                  handleSetOverviewState={handleSetOverviewState}
                  startDraggingFolder={startDraggingFolder}
                  filterFolderId={filterFolderId}
                  draggedFolderId={draggedFolderId}
                  starOrRemoveStarOnFolder={starOrRemoveStarOnFolder}
                  isReadOnly={isReadOnly}
                  handleSetAppState={handleSetAppState}
                  folderSettings={folderSettings}
                  allFoldersStatus={allFoldersStatus()}
                />
                <div className="uncategorized-and-archived">
                  <span
                    className={uncategorizedFolderContainerClassName}
                    onClick={() => handleClickOnFolder(handleSetOverviewState && refreshSelections, '')}
                    onContextMenu={e => e.preventDefault()}
                  >
                    <img
                      src={ClosedFolder}
                      className="selection-folder-icon folder-close"
                      style={{
                        pointerEvents: folderBorderDragOver && 'none',
                      }}
                      alt="icon"
                    />
                    <p
                      className={uncategorizedFolderTitleClassName}
                      onMouseDown={e => e.preventDefault()}
                      title="Uncategorized Selections"
                    >
                      Uncategorized Selections
                    </p>
                  </span>
                </div>

                {!hideArchivedSelections &&
                  (
                    <div className="uncategorized-and-archived">
                      <span
                        className={archiveFolderContainerClassName()}
                        onClick={() => handleClickOnFolder(true, 'archivedSelectionFolderId')}
                        onMouseDown={e => e.preventDefault()}
                        onDragOver={(e) => {
                          if (!isArchived) {
                            e.preventDefault();
                          }
                        }}
                        onDrop={(e) => {
                          onDropHandler(draggedSelectionId, e);
                          makeBorder(e, false);
                        }}
                        data-id="archivedSelectionFolderId"
                        data-name="Archived Selections"
                        onDragEnter={e => makeBorder(e, true)}
                        onDragLeave={e => makeBorder(e, false)}
                        draggable={false}
                        onContextMenu={e => e.preventDefault()}
                      >
                        <img
                          src={ClosedFolder}
                          className="selection-folder-icon folder-close"
                          style={{
                            pointerEvents: folderBorderDragOver && 'none',
                          }}
                          alt="icon"
                        />
                        <p
                          title="Archived Selections"
                          // eslint-disable-next-line react/no-danger
                          className={archivedSelectionsFolderTitleClassName}
                          style={{
                            pointerEvents: folderBorderDragOver && 'none',
                          }}
                        >
                          {isWaterfall ? 'Archived Waterfall Selections' : 'Archived Selections'}
                        </p>
                      </span>
                    </div>
                  )}

              </div>
            </div>
          )}
        {featureSelectionFolders ?
          null :
          (
            <div className="feature-folder-upgrade">
              <p>With DESelect Segment you can organize your Selections into folders for better sorting.</p>
              <p>
                Upgrade to a
                {' '}
                <a
                  href="https://deselect.com/contact/"
                  target="_blank"
                  rel="noreferrer">
                  Paid plan
                </a>
                {' '}
                to start using the feature.
              </p>
            </div>)}
      </div>

      <span
        className={`overview_folders_wrapper-hide ${hideFolders && 'hide'}`}
        onClick={handleHideFolders}
      >
        <img
          className={`penguin_for_hide_show_folders ${hideFolders && 'rotate_penguin'}`}
          src={Arrow}
          alt="arrow"
        />
      </span>

    </div>
  );
};

NewSelectionFolders.propTypes = {
  /**
   * It helps to handle with click on some folder
   */
  handleFolderClicked: PropTypes.func.isRequired,
  /**
   * Right click to open sub menu
   */
  rightClick: PropTypes.func,
  /**
   * Drop folder on folder
   * Drop selection on folder
   */
  dropOnFolder: PropTypes.func,
  /**
   *  make border when drag over
   */
  folderBorderDragOver: PropTypes.bool,
  /**
   * When dragging folder to folder
   */
  startDraggingFolder: PropTypes.func,
  /**
   * Function setting state of overview.js
   */
  handleSetOverviewState: PropTypes.func,
  /**
   * based on its id, display proper selections and open proper folder icon
   */
  filterFolderId: PropTypes.string,
  /**
   * id of dragged folder
   */
  draggedFolderId: PropTypes.string,
  /**
   * defines whether the component is read-only
   */
  isReadOnly: PropTypes.bool,
  /**
   * defines whether the component with folders is hidden
   */
  hideFolders: PropTypes.bool.isRequired,
  /**
   * defines blur where user can't drop
   */
  blurDrop: PropTypes.bool,
  /**
   * The number of pixels for the offsetTop property
   */
  offsetTop: PropTypes.number,
  /**
   * Refresh and set the selections state on the front page
   */
  refreshSelections: PropTypes.func,
  /**
   * Handle Open archive selection modal
   */
  handleOpenArchiveSelectionModal: PropTypes.func,
  /**
   * It open/close all created folders
   */
  openAllFolders: PropTypes.bool.isRequired,
  /**
   * id of dragged selection
   */
  draggedSelectionId: PropTypes.string,
  /**
   * array with nested folders
   */
  nestedFolders: PropTypes.instanceOf(Array).isRequired,
  /**
   * id of selected folder
   */
  folderId: PropTypes.string.isRequired,
  /**
   * It open/close all uncategorized folders
   */
  openSelections: PropTypes.bool,
  /**
   * Hides component with folders structure
   */
  handleHideFolders: PropTypes.func.isRequired,
  /**
   * Hides archived Selections
   */
  hideArchivedSelections: PropTypes.bool,
  /**
   * Indicates whether folders for selection are fetched
   */
  loadingFolders: PropTypes.bool.isRequired,
  /**
   * Indicates whether it's waterfall or not
   */
  isWaterfall: PropTypes.bool.isRequired,
  /**
   * indicates whether the loading modal is to be shown
   */
  showLoadingModal: PropTypes.bool.isRequired,
  /**
   * it sets the App component`s state
   * This prop will be passed from App.js component through Overview.js
   */
  handleSetAppState: PropTypes.func,
  /**
   * Object with a saved folder settings
   */
  folderSettings: PropTypes.instanceOf(Object),
  /**
   * An array with a flattened structure of all folders
   */
  allFolders: PropTypes.instanceOf(Array),
  /*
   * Indicates if dragged selection is archived
   */
  isArchived: PropTypes.bool,
  /**
   * It toggles a feature advert modal on with specific feature
   */
  showEssentialsUpgradeModal: PropTypes.func.isRequired,
  /**
   * Indicates if featureSelectionFolders is enabled
   */
  featureSelectionFolders: PropTypes.bool.isRequired,
  /**
   * It marks a folder as favorite or removes it from favorites
   */
  starOrRemoveStarOnFolder: PropTypes.func,

};

export default NewSelectionFolders;
